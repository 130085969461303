import Vue from 'vue';
// import Meta from 'vue-meta'
import Router from 'vue-router';
import store from '../store'
import Main from '../components/Main.vue'
import homeContent from '../components/homeContent.vue';
import contentDetailsSite from "../components/content/contentDetailsSite.vue"
import ActivateUserCandidate from '../components/candidate/ActivateUserCandidate.vue'
import StepsAfterRegisterCandidate from "../components/candidate/StepsAfterRegisterCandidate.vue"
import LoginCandidateComponent from '../components/candidate/LoginCandidateComponent.vue'
import RegisterCandidateComponent from '../components/candidate/RegisterCandidateComponent.vue'
import ForgotPasswordCandidate from '../components/candidate/ForgotPasswordCandidate.vue'
import ChangePassword from '../components/candidate/ChangePasswordCandidate.vue'
import candidateComponent from '../components/candidate/CandidateComponent.vue'
import DashboardComponent from '../components/candidate/Profile/Dashboard.vue'
import CancelNewsletter from '../components/candidate/cancelNewsletterComponent.vue'
import CareersComponent from '../components/careers/CareersComponent.vue'
import careerDetails from '../components/careers/careerDetails.vue'
import error404 from '../components/error/error404.vue'
import recoveryPassword from '../components/candidate/recoveryPassword.vue'

Vue.use(Router);
// Vue.use(Meta);

const routes = [
  {
    path: '/',
    component: Main,
    children: [
            
            { path: '/', component: homeContent, name: 'site.contents'},
            {
              path: "/contents/:id/show",
              component: contentDetailsSite,
              name: 'detailsRoute',
              props: true
            },
            { path: '/steps-after-register/:success/:email', component: StepsAfterRegisterCandidate, name: 'site.steps_after_register', props: true },
            {
              path: '/careers', 
              component: CareersComponent,
              name: 'site.careers_page'
            },
        ]
  },
  {
    path: '/careers/:slug',
    component: careerDetails,
    name: 'site.careers_page.details',
    props: true
  },
  {
    path: '/profile',
    component: candidateComponent,
    meta: {auth: true},
    children: [
      { path: '', component: DashboardComponent, name: 'candidate.dashboard' },
    ]
  }
  ,
    {
      path: '/candidate/forgotpassword',
      component: ForgotPasswordCandidate,
      name: 'candidate.forgotpassword'
    },
    
    {
        path: '/candidate/login',
        component: LoginCandidateComponent,
        name: 'candidate.login'
    },
    {
      path: '/candidate/register',
      component: RegisterCandidateComponent,
      name: 'candidate.register'
    },
    { path: '/candidate/activate-user', 
      component: ActivateUserCandidate,
      name: 'candidate.activate_user', 
      props: (route) => ({ id: route.query.id, email: route.query.email }) },
      
    { path: '/cancel_newsletter', 
      component: CancelNewsletter,
      name: 'candidate.cancel_newsletter', 
      props: (route) => ({ id: route.query.id, email: route.query.email }) },
    
    {
        path: '/candidate/changepassword',
        component: ChangePassword,
        name: 'candidate.chagepassword',
        meta: {auth: true}
    },
    {
      path: '/candidate/recovery-password/:token',
      component: recoveryPassword,
      name: 'candidate.recovery_password',
      props: true
    },
    
    {
        path: "*",
        name: 'site.error404',
        component: error404,
    }
];
const router = new Router({
    mode: 'history',
    fallback: true,
    routes
});
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth)) {
      if (store.getters.isLoggedIn) {
        next()
        return
      }
      next('/candidate/login')
    } else {
      next()
    }
})
export default router;