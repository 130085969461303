<template>
    <v-container>
        <validation-observer
        ref="observer"
        v-slot="{  }"
        >
            <form @submit.prevent="submit">
                <validation-provider
                    v-slot="{ errors }"
                    name="Tipo de documento"
                    rules="required"
                >
                    <v-select
                    v-model="anexo.tipo_documento"
                    :items="tipoList"
                    :error-messages="errors"
                    prepend-icon="mdi-none"
                    label="Tipo de documento"
                    data-vv-name="select"
                    required
                    ></v-select>
                </validation-provider>
                
                    <v-text-field
                    v-if="disableInstituicao"
                    v-model="anexo.instituicao"
                    prepend-icon="mdi-job"
                    label="Emitido por"
                    />
                
                <validation-provider
                    v-slot="{ errors }"
                    name="Documento"
                    rules="required"
                >
                    <v-file-input
                    label="Documento"
                    @change="selectFile"
                    :error-messages="errors"
                    accept=".doc, .docx, .pdf,"
                    
                    ></v-file-input>
                </validation-provider>
                <v-card-actions class="">
                    <v-row>
                        <v-col v-if="isUpdate" md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="update"
                            >Guardar</v-btn>
                        </v-col>
                        <v-col v-else md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="submit"
                            >Adicionar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
							v-if="isUpdate"
                            text
                            color="error"
                            outlined
                            @click="remove"
                            >apagar</v-btn>
                        </v-col>
                        
                    </v-row>
                    
                
                
                </v-card-actions>
            </form>

        </validation-observer>
    </v-container>
</template>
<script>
    import { required, digits, email, max, regex, integer } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Email must be valid',
    })
export default {
    components: {ValidationObserver, ValidationProvider},
    props: {
        anexo: {
            required: false,
            type: Object,
            default() {
                return {
                    tipo_documento: '',
                    instituicao: '',
                    documento: null
                }
            }
        },
        isUpdate: {
            require: false,
            type: Boolean,
            default: false
        }
        
    },
    
    data() {
        return {
            tipoList: [
                'Certificado',
                'Curriculum Vitae',
                'Diploma',
                'Portfolio',
                'Outro'
            ]
        }
    },
    computed: {
        disableInstituicao() {
            if(this.anexo.tipo_documento == 'Certificado' || this.anexo.tipo_documento == 'Diploma'){
                return true
            }
            return false
        }
    },
    methods: {
        selectFile(file) {
            
            this.anexo.documento = file
           /* 
            this.progress = 0;
            this.message = "";*/
        },
        submit() {
            this.$store.dispatch('loaderChange', true)
            const formData = new FormData()

            formData.append('tipo_documento', this.anexo.tipo_documento)
            if(this.anexo.instituicao){
                formData.append('instituicao', this.anexo.instituicao)
            }
            if(this.anexo.documento != null){
                formData.append('documento', this.anexo.documento)
            }
            this.$store.dispatch('anexo', formData)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
        },
        update() {
            this.$store.dispatch('loaderChange', true)
            let formData = new FormData()

            
            if(String(this.anexo.documento)){
                formData = {
                    'tipo_documento': this.anexo.tipo_documento,
                    'instituicao': this.anexo.instituicao,
                    'documento': null
                }
            } else {
                formData.append('tipo_documento', this.anexo.tipo_documento)
                formData.append('instituicao', this.anexo.instituicao)
                if(this.anexo.documento){
                    formData.append('documento', this.anexo.documento)
                }
            }
            const data = { 
                data: formData,
                id: this.anexo.id
            
            }
            this.$store.dispatch('updateAnexo', data)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
        },
        remove(){
			console.log('removed')
            const data = {
                table: 'anexo',
                id: this.anexo.id
            }
            //deletData
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('deleteData',data)
            .then(() => {
                this.$router.go()
                this.$store.dispatch('loaderChange', false)
                
            })
		}
    }
    
}
</script>