<template>
    <div class=""></div>
</template>

<script>
export default {
    props: {
        token: {
            required: true
        }
    },
    created() {
        this.setUserData()
    },
    methods: {
        setUserData() {
            this.$store.dispatch('loaderChange', true)
            const token = this.token
            localStorage.setItem('token', token)
			this.$store.state.token = token
            this.$store.dispatch('me')
                .then(() => {
                    this.$router.push({name: 'candidate.chagepassword'})
                    this.$store.dispatch('loaderChange', false)
                })
        }
    }

}
</script>

<style>

</style>