<template>
	<v-row wrap>
		<div :class="!isMobile ? 'home-mobile' : 'home'">
			<v-btn
			v-if="!isMobile"
			tile
			color="#F59919"
			text
			dark
			@click="home"
			>
				<v-icon v-if="!isMobile" size="27px" left>
					mdi-home
				</v-icon>
				voltar
			</v-btn>
			<v-btn
			v-else
			tile
			color="#009AAB"
			text
			dark
			@click="home"
			>
				<v-icon large left>
					mdi-home
				</v-icon>
				Voltar a vagas
			</v-btn>

		</div>
		<v-col cols="6" v-if="$vuetify.breakpoint.smAndUp" style="background-color: #F0F2F5;">
			<v-container fill-height style="heigth: 100vh;">
				<v-row wrap justify="center" align="center">
					<v-col cols="6">
						<v-row wrap>
							<v-col cols="12">
								<v-img
									:src="
										require('../../assets/img/vagas.png')
									"
									max-width="150"
									max-height="150"
									alt="Gugla Empresas"
								>
								</v-img>
								<p>
									Vagas de emprego das maiores empresas de Moçambique encontram-se aqui. Novas vagas todos os dias.
								</p>
							</v-col>
						</v-row>
						<!-- <v-row wrap>
							<v-col cols="12">
								<p class="font-weight-bold" style="padding: 10px">
									Em casos de dificuldade contacte:
								</p>
								<p style="padding: 10px">
									<v-icon color="#c61584">
										mdi-email
									</v-icon>
									info@gugla.co.mz
								</p>
							</v-col>
						</v-row> -->
					</v-col>
				</v-row>
			</v-container>
		</v-col>
		<v-col :cols="isMobile ? '6' : '12'" style="background-color: #F0F2F5;">
			<v-container fill-height style="height: 100vh;">
				<v-row wrap justify="center" align="center">
					<v-col cols="12" md="6"
						>
						
						<v-container fill-height fluid>
							<v-row wrap justify="center" align="center">
								<v-col>
									<v-card class="elevation-12">
												<validation-observer
													ref="loginForm"
													v-slot="{  }"
												>
													<v-form @submit.prevent="login">
														<v-card>
															<v-card-title style="background-color:#009AAB;">
																<v-row justify="center">
																	<span class="text1">Entrar</span>
																</v-row>
															</v-card-title>
															<v-divider style=""></v-divider>
															<v-card-text>
																<validation-provider
																	v-slot="{ errors }"
																	rules="required"
																	name="Email"
																>
																	<v-text-field
																		v-model="formData.email"
																		label="Email"
																		:error-messages="errors"
																	/>
																</validation-provider>
																<validation-provider
																	v-slot="{ errors }"
																	rules="required"
																	name="Senha"
																>
																	<v-text-field
																		v-model="formData.password"
																		:append-icon="
																			show1 ? 'mdi-eye' : 'mdi-eye-off'
																		"
																		:rules="[rules.required]"
																		:type="show1 ? 'text' : 'password'"
																		name="input-10-1"
																		label="Password"
																		hint="Mínimo de 7 caracteres"
																		counter
																		:error-messages="errors"
																		@click:append="show1 = !show1"
																	></v-text-field>
																</validation-provider>
																<span>Ainda não tem conta? <span class="register" @click="register"> Registe-se.</span></span>
																
															</v-card-text>
															<v-card-actions>
																<v-btn
																	@click="login"
																	small
																	elevation="4"
																	dark
																	color="#009AAB"
																	>Login</v-btn
																>
																<v-spacer></v-spacer>
																<v-btn
																	depressed
																	rounded
																	text
																	small
																	@click="forgotPassword"
																	>Esqueceu a senha?</v-btn
																>
															</v-card-actions>
														</v-card>
													</v-form>
												</validation-observer>
											
									</v-card>
								</v-col>
							</v-row>
						</v-container></v-col
					>
				</v-row>
			</v-container>
		</v-col>
		<loader/>
	</v-row>
</template>

<script>
import Loader from '../loader/Loader.vue'
import Swal from "sweetalert2";
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { required, digits, email, confirmed,max, regex } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
	...required,
	message: "{_field_} não pode estar vazio!",
});

extend("digits", {
	...digits,
	message: "{_field_} deve conter {length} dígitos. ({_value_})!",
});

extend("email", {
	...email,
	message: "O email deve ser válido!",
});
extend("confirmed", {
	...confirmed,
	message: "O valor para o campo {_field_} não coincide!",
});


extend('strong_password', {
	message: field => 'A ' + field + ' deve conter pelo menos 1 caracter minúsculo, 1 caracter maiúsculo, 1 dígito e um cumprimento não inferior a 7!',
	validate: value => {
		// let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/); // Com caracteres especiais
		let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
		return pattern.test(value);
	}
});
export default {
	components: {
		Loader,
		ValidationProvider,
		ValidationObserver,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		},
		getCandidate() {
			return this.$store.getters.getCandidate;
		}
	},
	created(){
		var linkTag = document.createElement('link')
		linkTag.setAttribute('rel', 'canonical')
		linkTag.setAttribute('href', `https://empregos.gugla.co.mz/candidate/login`)
		document.head.appendChild(linkTag)
	},
	data() {
		return {
			tab: null,
			formData: {
				email: "",
				password: "",
			},

			candidate: {
				name: "",
				last_name:"",
				email: "",
				password: "",
				
			},
			confirm_password: "",

			show1: false,
			password: "Password",
			rules: {
				required: (value) => !!value || "Obrigatório.",
				min: (v) => v.length  >= 7  || "Mínimo de 7 caracteres",
				emailMatch: () => `The email and password you entered don't match`,
			},

			//candidate: {},
		};
	},

	methods: {
		register() {
			this.$router.push({name: 'candidate.register'})
		},
		async login() {
				const isValidLoginForm = await this.$refs.loginForm.validate();
				if (!isValidLoginForm) {
					Swal.fire({
						title: "Alerta de Validação!",
						html: `Verifque o preenchimento dos campos antes de enviar o formulário.`,
						icon: "warning",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF4500",
					});

					return;
				}

				
				this.$store.dispatch('loaderChange', true)
				this.$store.dispatch('login', this.formData)
				.then((response) => {
					this.$store.dispatch('me')
					this.candidate = this.getCandidate;

					

					
					
					console.log(response)
					this.$store.dispatch('changeLoginDialog', false)
					//this.$router.push('/')
					if (response.data.candidate.first_login == true) {
						// Já fez o primeiro login
						this.$router.push({ name: "site.contents" });
					} else if (response.data.candidate.first_login == false) {
						// Ainda não fez o primeiro login/obriga a trocar a senha
						this.$router.push({ name: "candidate.chagepassword" });
					}
					this.$store.dispatch('loaderChange', false)
				})
				.catch((error) => {
					this.$store.dispatch('loaderChange', false)
					console.log(error);
				});
			
		},

		async autoRegister() {
			const isValidAutoRegisterForm = await this.$refs.autoRegisterForm.validate();

			if (!isValidAutoRegisterForm) {
				Swal.fire({
					title: "Alerta de Validação!",
					html: `Verifque o preenchimento dos campos antes de enviar o formulário.`,
					icon: "warning",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF4500",
				});

				return;
			}

			this.$store.dispatch('loaderChange', true)
			this.$store
				.dispatch("register", this.candidate)
				.then((response) => {
					// this.candidate = response.candidate;
					// Swal.fire({
					// 	title: "Sucesso!",
					// 	html: `A sua conta foi registada!<br> Aceda ao seu email para activar a sua conta e extrair os dados de acesso.`,
					// 	icon: "success",
					// 	confirmButtonText: "Ok",
					// 	confirmButtonColor: "#FF1744",
					// }).then(() => {
					// 	this.$router.push({ name: "candidate.login" });
					// });
					this.$router.push({ name: "site.steps_after_register", params: { success: 1, email: this.candidate.email} });
					this.$store.dispatch('loaderChange', false)
				})
				.catch(() => this.$store.dispatch('loaderChange', false));
		},
		forgotPassword(){
			this.$router.push({name: 'candidate.forgotpassword'})
		},
		home() {
			this.$router.push({name: 'site.contents'})
		}
	},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
.red-gugla {
	color: #ff1744;
}

.basil {
	background-color: #fffbe6 !important;
}
.basil--text {
	color: #356859 !important;
}

.shadow-left {
	box-shadow: 20px 20px 20px 20px #888888; /* for all browsers */
	-moz-box-shadow: 20px 20px 20px 20px #888888; /* for old firefox */
	-webkit-box-shadow: 20px 20px 20px 20px #888888; /* override for Chrome & Safari */
}
.text1 {
	text-align:center; 
	font-size:35px; 
	text-transform:uppercase; 
	color:rgb(255, 255, 255); 
	letter-spacing:1px;
	font-family:"Roboto", sans-serif;
	padding: 20px;
}
.register {
	color: cornflowerblue;
}
.register:hover {
	cursor: pointer;
}
.home {
	position: absolute;
	top: 30px;
	left: 30px;
}
.home-mobile {
	position: absolute;
	top: 15px;
	left: 15px;
}
</style>