<template>
  <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      color="#2b3233"
      class="white--text text-center"
      width="100%"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text>
        <v-btn
        v-for="link in links"
        :key="link"
        color="white"
        text
        rounded
        class="my-2"
        :href="link.link"
      >
        {{ link.name }}
      </v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        Um Producto da Marrar, Lda | Copyright © {{new Date().getFullYear()}}
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      links: [
        {
          'name': 'Recrutadores',
          'link': '/careers'  
        },
        {
          'name': 'Divulgação de Vagas e Seleção de Candidatos',
          'link': 'https://wa.me/+258845436085'  
        }
      ],
    }),
  }
</script>