<template>
    <!-- <div class="text-center">
    <v-dialog
      v-model="loginDialog"
      persistent
      width="500"
    >
        

        <v-card style="max-height: 250px; overflow: hidden;">
            <v-card-text>
            </v-card-text>
                
            <v-card-text>
            <v-row>
                
                    <div class="close">
                        <v-btn
                        large
                        text
                        color="primary"
                        @click="()=>{this.$store.dispatch('changeLoginDialog', false)}">
                            <v-icon large> 
                                mdi-close-thick
                            </v-icon>
                        </v-btn>
                    </div>
               
                <v-row>
                <v-col :cols="isMobile ? '7' : '12'" style="margin-top:10px;">
                    <h2 :class="isMobile ? 'text' : 'text-mob'">Navegue mais rapido ao efectuar <br v-if="!isMobile"> <span class="login" style="color" @click="() => {this.$router.push({name: 'candidate.login'})}">Login</span> no Gugla Empregos.</h2>
                    <h4 :class="isMobile ? 'sub-text' : 'sub-text-mob'">Saiba como se candidatar as vagas e efectue a sua candidatura com facilidade.</h4>
                    <div style="margin-top:10px;">
                        <span class="text2"> <span class="login" @click="goToLogin"> Entrar </span> ou <span class="login " @click="goToRegister"> Criar conta</span></span>
                    </div>
                </v-col>
                <v-col v-if="isMobile" cols="5" style="margin-top:10px">
                    <img src="../../../assets/img/login.svg" alt="" :class="isMobile ? 'img-pop' : 'img-mob'">
                </v-col>
                </v-row>
            </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    </div> -->
        <v-dialog
        v-model="loginDialog"
        persistent
        scrollable
        
        width="450"
    
        >
        <v-card
        min-height="100px">
            <v-card-text style="margin-top: 20px;">
                <v-row>
                    <div style="margin-top:7px; font-size:20px;" class="text"><span class="text-h6"> Estamos nos mudando para <a href="https://vaga.co.mz">https://vaga.co.mz</a>.</span></div>
                    <div style="margin-top: 20px;">Será redirecionado em {{ countDown }} segundos</div>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            countDown: 10,
        }
    },
    computed: {
        loginDialog() {
            // return this.$store.getters.loginDialog
            return true
        },
        isLoggedIn () {
          return this.$store.getters.isLoggedIn
        },
        isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		}
    },
    created() {
        this.countDownTimer()
    },
    methods: {
        goToLogin() {
            this.$store.dispatch('changeLoginDialog', false)
            this.$router.push({name: 'candidate.login'})
        },
        goToRegister() {
            this.$store.dispatch('changeLoginDialog', false)
            this.$router.push({name: 'candidate.register'})
        },
        countDownTimer () {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                } else {
                    // this.$router.push('https://vaga.co.mz')
                }
            }
    }
}
</script>

<style scoped>
.login {
    color: cornflowerblue;
    font-size: 0.8em;
}
.login:hover {
    cursor: pointer;
}
.span-style {
    font-size: 16px;
}
.img-pop {
    height: 160px;
}
.text {
    font-size: 21px;
}
.text2 {
    font-size: 16px;
}
.mainText {
    font-size: 0.8em;
}
.mainText::after {
    display: inline-block;
    width: 100%;
}
.sub-text {
    font-size: 15px;
}
.sub-text-mob {
    font-size: 14px;
}
.img-mob{
    position: relative;
    right: 40px;
    top: 30px;
    height: 120px;
}
.close {
    position: absolute;
    right: 0;
}

.btn {
  color: #c61584;
  cursor: pointer;
}
.position-btn {
  position: absolute;
  top: 0px;
  right: 5px;
}
.text {
  display: inline-block;
  width: 96%;
}
</style>