<template>
  <v-container>
    <!--<v-card
    class=" my-12"
    flat
    max-width="1000">-->
    <v-card-title>Documentos</v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text  v-for="anexo in getCandidate.anexos" :key="anexo.id">
        <!--<v-row wrap align="" >
          <v-col  lg="3" md="12" sm="12" v-for="anexo in getCandidate.anexos" :key="anexo.id" style="margin-left: 20px;">
            <v-btn icon :href="anexo.documento">
              <v-icon>mdi-file-download</v-icon>
              <div>{{anexo.tipo_documento}}</div>
            </v-btn>
          </v-col>
        </v-row>-->
        
        <v-row wrap>
			<v-col md="9" sm="6" style="margin-left: 30px;" >
				<v-btn class="btn" icon :href="anexo.documento" x-large>
					<v-icon>mdi-file-download</v-icon>
					<div>{{anexo.tipo_documento}}</div>
				</v-btn>
			</v-col>
			<v-col cols="2" align-self="center" style="margin-left: 30px;">
				<v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                >

                    <template v-slot:activator="{ on, attrs }">
                        
                            <v-btn v-bind="attrs" v-on="on" depressed rounded text small>
                                <v-avatar >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-avatar>
                            </v-btn>
                            
                    </template>
                    <template v-slot:default="dialog">
                        <v-toolbar
                        color="secondary"
                        dark
                        >
						<v-row>
                            <v-col cols="9">
                                Editar Documento
                            </v-col>
                            <v-col cols="3">
                                <v-btn color="secondary" @click="dialog.value = false">
                                    <v-icon color="" large>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-col>				
                        </v-row>
						</v-toolbar>
                        <v-card>
                            <anexo :anexo="anexo" :isUpdate="true"/>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>
        
    </v-card-text>
    <v-card-actions>
        <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        >

            <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="secondary "
                v-bind="attrs"
                v-on="on"
                text
                >
                    Adicionar Documento
                </v-btn>     
            </template>
            <template v-slot:default="dialog">
                <v-toolbar
                color="secondary"
                dark
                >
					<v-row>
                            <v-col cols="9" >
                                Adicionar Documento
                            </v-col>
                            <v-col cols="3" >
                                <v-btn color="secondary" @click="dialog.value = false">
                                    <v-icon color="" large>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-col>				
                        </v-row>
				</v-toolbar>
                <v-card>
                    <!--form-->
                    <anexo/>
                    <v-card-actions>
                        
                        
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-card-actions>

    <!--</v-card>-->
    </v-container>
</template>

<script>
import Anexo from './forms/AnexoForm.vue'
export default {
    components: {Anexo},
    computed: {
      getCandidate() {
        let candidate = this.$store.state.candidate
        
        return candidate
      }
    },
}
</script>

<style scoped>

.v-btn__content {
    display:flex;
    flex-direction:column;
}
.btn {
    display:flex;
    flex-direction:column;
}
</style>