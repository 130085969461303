import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import {BASE_URL} from '../services/config'
import {API} from '../services/api'


Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        email: '',
        status: '',
        publicities: [],
        loader: false,
        changeValue: false,
        loginDialog: false,
        applyVagaDialog: false,
        vaga: null,
        cadidaturas: [],
        completeProfile: false,
        popupCount: 0,
        popupLoginCount :0,
        token: localStorage.getItem('token') || '',
        candidate: {
            id: '',
            first_login: '',
            name: '',
            last_name: '',
            receive_newsletter: '',
            
        },
        
      
    },
    mutations: {
        set_candidate(state, params){
            state.candidate = params
        },
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, token, candidate){
          state.status = 'success'
          state.token = token
          state.candidate = candidate
        },
        auth_register(state, candidate){
          state.candidate = candidate
        },
        auth_error(state){
          state.status = 'error'
        },
        logout(state){
          state.status = ''
          state.token = ''
          state.candidate = {
              id: '',
              first_login: '',
              name: '',
              last_name: '',
              receive_newsletter: '',
              
          }
        },
        forgotValue(state, value){
            state.forgotValue = value
        },
        loaderChange(state, value){
            state.loader = value;
        },
        changeValue(state, value){
              state.changeValue = value
          },
          SET_EMAIL(state, value){
              state.email = value
          },
        loginDialogActive(state, value) {
            state.loginDialog = value
        },
        completeProfileActive(state, value) {state.completeProfile = value
            /*if(!console.log(VueCookies.get('showPopUp')))
                VueCookies.set('showPopUp', true, '5s')
            console.log(VueCookies.get('showPopUp'))*/
        },
        applyVagaDialogActive(state, params) {
            state.applyVagaDialog = params.value
            state.vaga = params.id
        },
        candidaturas(state, value) {
            state.cadidaturas = value
        },
        publicities(state, value) {
            state.publicities = value
        }
    
    },
    actions: {
        getContent({commit}, params) {
            return new Promise((resolve, reject) => {
                // api.get(`guest/contents/${params.id}`)
                //     .then(res => {
                //         commit('auth_request')
                //         resolve(res)
                //     })
                //     .catch(err => {
                //         reject(err)
                //     })
                axios.get(`${API}/guest/contents/${params.id}`)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        login({commit}, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}login`, 
                    data: params, 
                    method: 'POST'
                })
                .then(res => {
                    
                    let token = res.data.access_token
                    let candidate = res.data.candidate
                    localStorage.setItem('token', token)
                    window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                    let payload = {
                        user: {
                            id: candidate.id,
                            first_login: candidate.first_login,
                            name: candidate.name,
                            last_name: candidate.last_name,
                            receive_newsletter: candidate.receive_newsletter,
                            
                        }
                    }
                    commit('auth_success', token, payload)
                    resolve(res)
                })
                .catch(err => {
                    commit('auth_error')
                    //console.log(err)
                    localStorage.removeItem('token')
                    reject(err)
                })
            })
        },
        register({commit}, params){
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}register`, 
                    data: params,
                    method: 'POST'
                })
                .then(res => {
                    const candidate = res.data.data
                    //console.log(candidate)
                    commit('auth_register', candidate)
                    resolve(res)
                })
                .catch(err => {
                    commit('auth_error', err)
                    reject(err)
                })
            })
        },
        logout({commit}){
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve) => {
                axios.get(`${BASE_URL}logout`, config)
                .then(() => {
                    commit('logout')
                    localStorage.removeItem('token')
                    resolve()
                })
                .catch(() => {
                    commit('logout')
                    localStorage.removeItem('token')
                    resolve()
                })
                
                
            })
        },
        forgotPassword({commit},formData) {

            

            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}forgot_password`, formData)
                    .then((res) => {
                        commit('')
                        resolve(res)
                    })
                    .catch(error => {
                        //console.log(error);
                        reject(error)
                    })
                   
            })
        },
        changePassword({commit}, params){
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}change_password`, params, config)
                .then((res) => {
                    commit('auth_request')
                    resolve(res)
             
                })
                .catch(error => {
                   
                    reject(error)
                })
            })
        },
        activeCandidate({commit}, params){
            
            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}active?id=${params.id}&email=${params.email}`)
                .then(res => {
                    commit('auth_request')
                   
                    resolve(res)
                })
                .catch(error => {
                  
                    reject(error)
                })
            })
        },
        cancelNewsletter({commit}, params){
            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}newsletter/cancel?id=${params.id}&email=${params.email}`)
                .then(res => {
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        me({commit}) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}me`, config)
                .then(res => {
                    commit('set_candidate', res.data[0])
                    const candidaturas_array = res.data[0].contents.map(vaga => {
                        return vaga.id
                    })
                    commit('candidaturas', candidaturas_array)
                    resolve(res)
                })
                .catch(err => {
                    localStorage.removeItem('token')
                    reject(err)
                })
            })
        },
        activeNewsletter({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}receive-newsletter`, params, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        personalData({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}personal-data/create`, params, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        }
        ,
        experience({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}profissional-experience/create`, params, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        updateExperience({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}profissional-experience/update/${params.id}`, params.data, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        education({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}education/create`, params, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        updateEducation({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}education/update/${params.id}`, params.data, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        language({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}language/create`, params, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        updateLanguage({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}language/update/${params.id}`, params.data, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        anexo({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}anexo/create`, params, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        updateAnexo({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            // console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}anexo/update/${params.id}`, params.data, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        },
        deleteData({commit}, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.delete(`${BASE_URL}${params.table}/delete/${params.id}`, config)
                .then(res => {
                    commit('auth_request')
                    resolve(res)
                })
                .catch(
                    err => reject(err)
                )
            })
        }
        ,
        dialogValue({commit}, value) {
            commit('forgotValue', value)
        },
        loaderChange({commit}, value){
            commit('loaderChange', value)
        },
        changeValue({commit}, value){
            commit('changeValue', value)
        },
        setEmail({commit}, value){
            commit('SET_EMAIL', value)
        },
        changeLoginDialog({commit}, value) {
            commit('loginDialogActive', value)
        },
        changeCompleteProfile({commit}, value){
            //console.log(this.state)
            if(value == false){
                commit('completeProfileActive', value)
            }
            commit('completeProfileActive', value)
        },
        changeApplyVagaDialog({commit}, params) {
            commit('applyVagaDialogActive', params)
        },
        getPublicity({commit}, params) {
            commit('publicities', params)
        }
   
    },
    getters: {
        getCandidate: state => state.candidate,
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        loader: state => state.loader,
        changeValue: state => state.changeValue,
        email: state => state.email,
        loginDialog: state => state.loginDialog,
        completeProfile: state => state.completeProfile,
        applyVaga: state => state.applyVagaDialog,
        publicities: state => state.publicities,
    
    }
})