<template>
    <v-container>
        <validation-observer
        ref="observer"
        v-slot="{  }"
        >
            <form @submit.prevent="submit">
                <validation-provider
                v-slot="{ errors }"
                name="Idioma"
                rules="required"
                >
                    <v-text-field
                    v-model="language.idioma"
                    prepend-icon="mdi-job"
                    label="Idioma"
                    :error-messages="errors"
                    />
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="fala"
                    rules="required"
                >
                    <v-select
                    v-model="language.fala"
                    :items="falaList"
                    :error-messages="errors"
                    prepend-icon="mdi-none"
                    label="Fala"
                    data-vv-name="select"
                    required
                    ></v-select>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="Escrita"
                    rules="required"
                >
                    <v-select
                    v-model="language.escrita"
                    :items="falaList"
                    :error-messages="errors"
                    prepend-icon="mdi-none"
                    label="Escrita"
                    data-vv-name="select"
                    required
                    ></v-select>
                </validation-provider>
                <v-card-actions class="">
                    <v-row>
                        <v-col v-if="isUpdate" md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="update"
                            >Guardar</v-btn>
                        </v-col>
                        <v-col v-else md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="submit"
                            >Adicionar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
							v-if="isUpdate"
                            text
                            color="error"
                            outlined
                            @click="remove"
                            >apagar</v-btn>
                        </v-col>
                        
                    </v-row>
                </v-card-actions>
            </form>

        </validation-observer>
    </v-container>
</template>

<script>
    import { required, digits, email, max, regex, integer } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Email must be valid',
    })
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        language: {
            require: false,
            type: Object,
            default() {
                return {
                    idioma: '',
                    fala: '',
                    escrita: '',
                }
            }
        },
        isUpdate: {
            require: false,
            type: Boolean,
            default: false
        },
        id: {
            required: false,
            type: Number
        }
    },
    data() {
        return {
            falaList: [
                'Básico',
                'Intermédio',
                'Avançado',
                'Nativo'
            ]
        }
    },
    methods: {
        submit() {
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('language', this.language)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
        },
        update() {
            console.log(typeof(this.language.id))
            const data = { 
                data: {
                    idioma: this.language.idioma,
                    fala: this.language.fala,
                    escrita: this.language.escrita
                },
                id: this.language.id
            
            }
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('updateLanguage',data)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
        },
        remove(){
			console.log('removed')
            const data = {
                table: 'language',
                id: this.language.id
            }
            //deletData
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('deleteData',data)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
		}
    }

}
</script>

<style>

</style>