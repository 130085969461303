<template>
	<div>
		<v-card class="mx-auto d-flex flex-column" outlined elevation="1" min-height="180px">
			<!--<router-link :to="'contents/' + content.id + '/show'">-->		
			<v-list-item three-line  :to="{name: 'detailsRoute', params: {id: content.id}}">
				<div class="recomend-class" v-if="content.is_recomended">
					<v-chip x-small label dark :color="'green darken-1'">recomendado</v-chip>
				</div>
						
				<v-list-item-content>
					<p class="text-subtitle-2 text-sm-h6 mb1">
						{{content.titulo}}
					</p>
					<p class="text-subtitle-2 mb1" v-if="content.industria && content.industria != 'null'">
						{{content.industria}}
					</p>
					<v-list-item-subtitle>
						{{content.instituicao}}
					</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-avatar v-if="content.image" size="60" height="auto" rounded color="">
					<img :src="content.image" alt="">
				</v-list-item-avatar>
				<v-list-item-avatar v-else size="60" height="auto" rounded color="">
					<img :src="require('../../assets/img/content-default.png')" alt="">
				</v-list-item-avatar>
			</v-list-item>
			<v-spacer></v-spacer>
				<v-card-actions
					:class="[expired ? 'left-border-expired' : 'left-border-active']"
				>
					<v-chip small light outlined>
						Local: 
						<span v-if="content.local">
							{{content.local}}
						</span>
						<span v-else>
							<span v-for="local in content.locals" :key="local.id" class="mr-1">
								{{local.name}}
							</span>
						</span>
						
							
					</v-chip>
					<v-spacer></v-spacer>
					<v-chip 
					small
					light
					outlined
					:color="expired ? 'red darken-1' : 'green darken-1'">
						Validade: {{content.validade | formatDate}}
					</v-chip> 
					<!--<v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer> 
					<v-chip small light outlined class="hidden-xs-only">
						Fonte:
						
						<span
						class="font-weight-thin font-italic text-caption text-disabled"
						>
							{{ content.fonte }}
						</span>
					</v-chip>-->
			</v-card-actions>
						
		</v-card>
	</div>
  
</template>

<script>
export default {
	data() {
		return {}
	},
	props: {
		content: {
			required: true,
			type: Object
		}
	},
	computed: {
		expired() {
			let today = new Date();
			let deadLineDate = new Date(this.content.validade);
			today.setHours(0, 0, 0, 0);
			deadLineDate.setHours(0, 0, 0, 0);
			//console.log(today, " -- " , deadLineDate )
			
			return today > deadLineDate ? true : false;
		}
	},
	filters: {
		formatDate(date) {
			if (date) {
				let newDate = new Date(date);
				let newFormatedDate =
					newDate.getDate() +
					"-" +
					(newDate.getMonth() + 1) +
					"-" +
					newDate.getFullYear();
				return newFormatedDate;
			}

			return "";
		}
	}
}
</script>







<style>
.recomend-class{
	position: absolute;
	top: -10px;;
	right: -14px; /* Position them outside of the screen */
	width: 100px; /* Set a specific width */
	text-decoration: none; /* Remove underline */
	font-size: 20px; /* Increase font size */
	color: white; /* White text color */
}
.green-gugla {
	color: #76ff03;
}

.red-gugla {
	color: #ff1744;
}

.left-border-expired {
	/* red accent-3 */
	border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
	/* red accent-3 */
	border-bottom: 4px solid #ff1744 !important;
}

.left-active-card {
	border-left: 4px solid #FCC353;
}
.left-border-active {
	/* light-green accent-4 */
	border-left: 4px solid #64dd17 !important;
	/*  */
}

.bottom-border-active {
	border-bottom: 4px solid #64dd17 !important;
}

.card {
	background-color: #fff;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.87);
	/* margin: 8px;
	min-width: 290px; */
	overflow: hidden;
	position: relative;
}


.media--50x50 {
	height: 50px;
	width: 50px;
}

.media--50x50 > img {
	height: 100%;
	width: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
}
</style>