<template>

        
      
  <v-carousel hide-delimiters show-arrows-on-hover cycle :height="isMobile ? '130' : '120'" interval="11000">
      
      <v-carousel-item
        v-for="(publicity,i) in publicities"
        :key="i"
        class="img"
        :href="publicity.url"
        target="_blank"
        >
      
        <img :src="isMobile ? publicity.image_web : publicity.image_mobile" alt="">
      </v-carousel-item>
    </v-carousel>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          /*{
            src: 'https://docs.google.com/uc?id=15WQeOwQTfdoQpl77Hk7DE1BMaepxSbXu',
            mobile: 'https://docs.google.com/uc?id=1kqWkWNNi1TQi79xpTg4nDgRLbjvhywAb',
            url: 'https://www.marrar.co.mz'
          },*/
          {
            src: 'https://docs.google.com/uc?id=15uU-dF9_1lBu1Kms58y_039IbuIzA1Im',
            mobile: 'https://docs.google.com/uc?id=1DNeJmBJQ7AvazlBP5NXpc0ai5G5EGJas',
            url: 'https://gugla.co.mz/publicidade.html'
          },
          {
            src: 'https://docs.google.com/uc?id=1KPj0fV1moBjOEMtjxparn0C8EDnB85vT',
            mobile: 'https://docs.google.com/uc?id=1EtOUvO5C7hF3_1Y18khHQzlqJ9WXm4Qd',
            url: 'https://chat.whatsapp.com/Hv6DRyBC8gVL2sFpOrTD9I'
          },
          /*{
            src: 'https://docs.google.com/uc?id=1Y5KEhoybALwLCcOIrTWB6jehrQHqVzFi',
            mobile: 'https://docs.google.com/uc?id=1-yPxAjfFEwEmiww9PpPJAWT6D7DOqHdY',
            url: 'https://www.marrar.co.mz'
          },*/
          /*{
            src: 'https://docs.google.com/uc?id=19UwMQSPK2DhBeATlwMmYHxgNlchKdGE5',
            mobile: 'https://docs.google.com/uc?id=1DjD9xfjZ02LqTxaulUdCfS8ste3YQpAO',
            url: 'https://gugla.co.mz/publicidade.html'
          },*/
          /*{
            src: 'https://docs.google.com/uc?id=13F-Tn-mZjwjXkYxyFSGy0C05Pu-s3V6O',
            mobile: 'https://docs.google.com/uc?id=1ytQVbNv60ZJ8Ecg_EpZDVkcJE0ZATwU_',
            url: 'https://www.marrar.co.mz'
          },*/
          {
            src: 'https://docs.google.com/uc?id=1ankwuohz7e_yOX39QgKU24_sXK9UChde',
            mobile: 'https://docs.google.com/uc?id=1Ir-R5iRIsVJrj8WrFKSX9utABA8L_pBM',
            url: 'https://www.marrar.co.mz'
          }
        ]
      }
    },
    computed: {
      isMobile() {
        const width = this.$vuetify.breakpoint.width;
        
        return width > 650 ? true : false;
      },
      publicities() {
        return this.$store.state.publicities
      }
    }
  }  
    
</script>
<style scoped>
  .img {
      width: 100%;
      height: 100%;
      overflow: hidden;
  }
  .img  img{
    width: 100%;
    height: 100%;
  }
</style>