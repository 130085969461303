<template>
	<v-container>
		<v-row v-if="success == 1">
			<v-col cols="12">
				<v-alert
					type=""
					prominent
					
					
					class="text-center"
				>
					<h2 class="text-h6 text-md-h3 mt-2 success--text">
						Registado com sucesso!
					</h2>
				</v-alert>
				<v-card class="elevation-0">
					<v-card-text>
						<p class="text-justify text-body-1 text-md-h6">
							Caro utilizador, a sua conta foi criada com sucesso.
						</p>
						<p class="text-justify text-body-1 text-md-h6">
							Para aceder à sua conta queira por favor seguir os passos na
							sequência descrita abaixo:
						</p>

						<v-divider></v-divider>
						<v-list>
							<v-list-item
								v-for="(step, index_step) in this.steps"
								:key="index_step"
							>
								<v-list-item-icon>
									<!-- <v-icon color="secondary">
										mdi-arrow-right-bold-circle
									</v-icon> -->
                                    <v-avatar
                                    color="#c61584"
                                    class="subheading white--text"
                                    size="24"
                                    >
                                        {{ step.code}}
                                    </v-avatar>
								</v-list-item-icon>
								<v-list-item-content>
									<p class="text-justify text-body-2 text-md-body-1">
										{{ step.description }}
									</p>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="12">
				<v-alert type="error" prominent color="accent" class="text-center">
					<h2 class="text-h6 text-md-h3 mt-2">
						Algo inesperado aconteceu!
					</h2>
				</v-alert>
				<v-card class="elevation-0">
					<v-card-text>
						<p class="text-justify text-body-1 text-md-h6">
							Não foi possível criar a sua conta, por favor tente novamente.
						</p>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	computed: {
		
	},
    props: {
        success: {
            required: true
        },
		email: {
			required: true
		}
    },

	data() {
		return {
			steps: [
				{
					code: 1,
					description: `Vá até a sua caixa de emails recebidos, em ${this.email};`,
				},
				{
					code: 2,
					description: `Abra o email que lhe foi enviado pela plataforma Gugla Empregos e clique no botão apresentado para activar a sua conta (caso não encontre o email, verifique a caixa de Spam);`,
				},
				{
					code: 2,
					description: `Clicando no botão, a sua conta será activada`,
				},
			],
		};
	},
};
</script>
<style scoped>
</style>