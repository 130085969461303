<template>
<v-container>
    <!--<v-card
    class=" my-12"
    flat
    max-width="1000px">-->
	<v-card width="auto" flat>
    <v-card-title>Formação Academica</v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text v-for="education in getCandidate.education" :key="education.id">

		<v-row>
			<v-col cols="10">
			<p class="text-h6 text--primary">
			{{education.instituicao}}
			</p>
			<div>{{education.curso}} - {{education.grau}}</div>
			
			<p><span> {{education.estado}} </span></p>
			</v-col>
			<v-col cols="2" align-self="center">
                <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                >

                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" depressed rounded text small>
							<v-avatar >
								<v-icon>
									mdi-pencil
								</v-icon>
							</v-avatar>
						</v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-toolbar
                        color="secondary"
                        dark
                        >
                        <v-row>
                            <v-col cols="9">
                                Adicionar Formação
                            </v-col>
                            <v-col cols="3">
                                <v-btn color="secondary" @click="dialog.value = false">
                                    <v-icon color="" large>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-col>				
                        </v-row>
                        </v-toolbar>
                        <v-card>
                            <education-form :education="education" :isUpdate="true"/>
                            
                        </v-card>
                    </template>
                </v-dialog>
			</v-col>
		
		</v-row>
        
        
          
          
        <!--<div class="text--primary">
          {{experience.descricao}}
        </div>-->
      <v-divider style="100%"></v-divider>
    </v-card-text>
    <v-card-actions>
        <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        >

            <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="secondary "
                v-bind="attrs"
                v-on="on"
                text
                >
                    Adicionar Formação
                </v-btn>     
            </template>
            <template v-slot:default="dialog">
                <v-toolbar
                color="secondary"
                dark
                >
                    <v-row>
                        <v-col cols="9">
                            Adicionar Formação
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="secondary" @click="dialog.value = false">
                                <v-icon color="" large>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-col>				
                    </v-row>
                </v-toolbar>
                <v-card>
                    <education-form/>
                    
                </v-card>
            </template>
        </v-dialog>
    </v-card-actions>
	</v-card>
	</v-container>
    <!--</v-card>-->
</template>

<script>
import EducationForm from './forms/EducationForm.vue'
export default {
    components: {EducationForm},
    computed: {
      getCandidate() {
        let candidate = this.$store.state.candidate
        
        return candidate
      }
    },
}
</script>

<style>

</style>