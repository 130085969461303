import axios from 'axios'


// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'

function errorResponseHendler(error) {
    // console.log(error.response);
    // store.commit('loading/CHANGE_LOADING', false)
    // console.log('RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR FAAIIIIIILLLLLLL');
    if (error.config.hasOwnProperty.call('errorHandle') && error.config.errorHandle === false) {

        return Promise.reject(error);
    }
    console.log(error)

    // if has response show the error
    if (error.response) {

        // testando o store
        // let error_tes = store.state.auth.test
        let error_status = error.response.status

        if (error_status == 402) {

            Swal.fire({
                title: 'Conta bloqueada/desactivada!',
                text: error.response.data.error,
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        }
        if (error_status == 404) {
            // console.log(error.response.data.message);
            Swal.fire({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        } else if (error_status == 422) {

            Swal.fire({
                title: 'Erro de Validação!',
                text: error.response.data.errors.email,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        } else if (error_status == 400) {

            Swal.fire({
                title: 'Credenciais Invalidas!',
                text: error.response.data.error,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        } else if( error_status == 500) {

            Swal.fire({
                title: 'Erro de Validação!',
                text: 'Erro interno do servidor',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        }
        return Promise.reject(error);
    }


}



// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    errorResponseHendler
)

export default errorResponseHendler