/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
    <div>
		<v-app-bar app clipped-left color="white">
			<!-- <v-app-bar-nav-icon class="grey--text" @click.prevent="drawer = !drawer"></v-app-bar-nav-icon> -->
			<v-toolbar-title :to="'https://vaga.co.mz'">
				<router-link :to="'https://vaga.co.mz'">
				<img :src="require('../../assets/img/vagas.png')" height="35" alt="Vagas"/>
				</router-link>
				<!-- <img src="images/logo-830x354.png" height="45" alt="" /> -->
				
			</v-toolbar-title>

			<v-spacer></v-spacer>
			<!--<v-toolbar-item v-if="isLoggedIn" class="hidden-md-and-up">
				<v-menu content-class="elevation-0" right min-width="200px" offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-avatar size="48" v-bind="attrs" v-on="on" color="error">
						
						<img v-if="image" 
                            :src="getCandidate.personal_data.image"
                        />
						<img
                            v-else
                            :src="require('../../assets/img/user-png.png')"
                            alt=""
                        >
					</v-avatar>
				</template>
				<v-card class="mt-2" dark color="red acent-3">
					<v-list-item-content class="justify-center">
						<div class="mx-auto text-center">
							<v-avatar color="error">
								
								<img v-if="image" 
									:src="getCandidate.personal_data.image"
								/>

								<img
									v-else
									:src="require('../../assets/img/user-png.png')"
									alt=""
								>
							</v-avatar>

							<h3>{{getCandidate.name}} {{getCandidate.last_name}}</h3>
							
							
							<v-divider class="my-1"></v-divider>
							<v-btn depressed rounded text small>
								Perfil
							</v-btn>
							<v-divider class="my-1"></v-divider>
							<v-btn depressed rounded text small :to="{name: 'candidate.chagepassword'}">
								Alterar senha
							</v-btn>
							<v-divider class="my-1"></v-divider>
							<v-btn depressed rounded text small @click.prevent="logout"> Sair </v-btn>
						</div>
					</v-list-item-content>
					
				</v-card>
			</v-menu>
			</v-toolbar-item>-->
			<v-toolbar-items v-if="isLoggedIn">
				
					<v-btn href="https://vaga.co.mz" depressed rounded text small>
						<v-icon>mdi-briefcase</v-icon>
						<div class="hidden-sm-and-down">Vagas</div>
					</v-btn>
					
				
			</v-toolbar-items>
			<!--<v-toolbar-items >
		<v-dialog v-if="getCandidate.id" v-model="dialog_newsletters" transition="=dialog-top-transition" max-width="600">
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed rounded text small> 
					<v-icon>mdi-bell</v-icon>
					<div class="hidden-sm-and-down">Notificações</div>
				</v-btn>
				
			</template>
			<template>
				<v-form @submit.prevent="onSubmitReceiveNewsletter">
					<v-card>
					<v-toolbar
					color="secondary"
					dark
					>
					Activação de Notificações
					</v-toolbar>
					<v-card-text>
						<p class="text-body-2 text-md-body-1">
							Seja o primeiro a saber sobre novas oportunidades de empregos. Receba notificações sobre as novas vagas publicadas no Gugla Empregos
							.
						</p>
						
						<v-checkbox
						v-model="newsletter.receive_newsletter"
						color="secondary"
						label="Desejo receber as notificações das vagas publicadas."
						>

						</v-checkbox>
					</v-card-text>
					<v-card-actions>						
						<v-btn text type="submit">Submeter</v-btn>-->
						<!-- <v-spacer></v-spacer>
						<v-btn text @click="dialog_newsletters = false">Fechar</v-btn> -->
					<!--</v-card-actions>
				</v-card>
				</v-form>
			</template>
		</v-dialog>

		<v-dialog v-else v-model="dialog_newsletters_invite" transition="=dialog-top-transition" max-width="600">
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed rounded text small> 
					<v-icon>mdi-bell</v-icon>
					<div class="hidden-sm-and-down">Notificações</div>
				</v-btn>
				
			</template>
			<template>
					<v-card>
					<v-toolbar
					color="secondary"
					dark
					>
					Activação de Notificações
					</v-toolbar>
					<v-card-text>
						<p class="text-body-2 text-md-body-1" style="margin-top: 20px">
							Seja o primeiro a saber sobre novas oportunidades de empregos. Receba notificações sobre as novas vagas publicadas no Gugla Empregos.
						</p>
						<p class="text-body-2 text-md-body-1">
							Por favor registe-se no Gugla Empregos pelo <router-link :to="{ name: 'candidate.login'}" class="secondary--text text-decoration-none"> link </router-link> para activar as Notificações.
						</p>
					</v-card-text>
					<v-card-actions>						
						<v-btn text type="submit">Submeter</v-btn>
						<v-spacer></v-spacer> 
						<v-btn text @click="dialog_newsletters_invite = false">Fechar</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>
		</v-toolbar-items>-->
		
			<div>
				
				<v-btn v-if="!isLoggedIn" depressed rounded text small :to="{ name: 'candidate.login'}">
					
				</v-btn>
		
			

			<!--<v-menu v-else content-class="elevation-0" right min-width="200px" offset-y>

				<template v-slot:activator="{ on, attrs }">-->
				
				
				<v-toolbar-items v-else>
				
					<v-btn style="display:flex; flex-direction:column;" v-if="returnImage" @click="profile" depressed rounded text small>
						<v-avatar :size="isMobile ? '48' : '38'">
							<img 
								
								:src="getCandidate.personal_data.image"
							/>

							
						</v-avatar>
					</v-btn>
					<v-btn v-else @click="profile" depressed rounded text small>
						<v-avatar :size="isMobile ? '48' : '38'">
							<img 
								
								:src="require('../../assets/img/user-png.png')"
								alt=""
							/>

							
						</v-avatar>
					</v-btn>
					
				
				</v-toolbar-items>
				<!--</template>
				<v-card class="mt-2" dark color="red acent-3">
					<v-list-item-content class="justify-center">
						<div class="mx-auto text-center">
							<v-avatar color="error">
								
								<img v-if="returnImage" 
									:src="getCandidate.personal_data.image"
								/>

								<span v-else class="white--text" headline>{{
									initialsNameUser
								}}</span> 
							</v-avatar>

							<h3>{{getCandidate.name}} {{getCandidate.last_name}}</h3>
							
							
							<v-divider class="my-1"></v-divider>
							<v-btn depressed rounded text small :to="{name: 'candidate.dashboard'}">
								Perfil
							</v-btn>
							<v-divider class="my-1"></v-divider>
							<v-btn depressed rounded text small :to="{name: 'candidate.chagepassword'}">
								Alterar senha
							</v-btn>
							<v-divider class="my-1"></v-divider>
							<v-btn depressed rounded text small @click.prevent="logout"> Sair </v-btn>
						</div>
					</v-list-item-content>
					
				</v-card>
			</v-menu>-->
			</div>
			
			
		</v-app-bar>
		

		
	</div>
</template>

<script>
import Swal from 'sweetalert2'
//import MainNav from './MainNavSiteResponsive.vue'

export default {
	components: {},
	data () {
      return {
		initialsNameUser: '',
		dialog_newsletters_invite: false,
		dialog_newsletters: false,
			newsletter: {
				receive_newsletter: false,
			},
		image: '',
		drawer: false,
		group: null,
		candidate: {}
      }
    },
    computed: {
        isLoggedIn () {
          return this.$store.getters.isLoggedIn
        },
		isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		},
        getCandidate() {
			let candidate = this.$store.state.candidate
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if(candidate){this.newsletter.receive_newsletter = candidate.receive_newsletter}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if(candidate){this.initialsNameUser = candidate.name.charAt(0).toUpperCase() + candidate.last_name.charAt(0).toUpperCase()}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if(candidate?.personal_data && candidate.personal_data.image != null){this.image = true}
			
			return candidate
        },
		returnImage(){
			if(this.$store.state.candidate?.personal_data && this.$store.state.candidate.personal_data?.image){
				return true
			}
			return false
		}
		
    },
	watch: {
		group() {
			this.drawer = false;
		}
	},
	
    methods: {
        logout () {
			this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('logout')
            .then(() => {
				this.$router.push({ name: 'candidate.login'})
				this.$store.dispatch('loaderChange', false)
            })
        },
        login() {
            this.$router.push({ name: 'candidate.login'})
        },
        isChange() {
          console.log(this.initialsNameUser)
        },
		
		onSubmitReceiveNewsletter() {
			/*if(this.newsletter.receive_newsletter == true){
				this.newsletter.receive_newsletter = 1
			} else {
				this.newsletter.receive_newsletter = 0
			}*/
			this.$store
				.dispatch("activeNewsletter", this.newsletter)
				.then((response) => {
					if(this.newsletter.receive_newsletter) {
						Swal.fire({
						title: "Notificações",
						html: `Activou o serviço de notificação por email.<br/> Passará a Receber notificações sobre as novas vagas publicadas no Gugla Empregos.`,
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF4500",
					})
					}else{
						Swal.fire({
						title: "Notificações",
						html: `Desactivou o serviço de notificação por email.`,
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF4500",
					})
					}
					this.dialog_newsletters = false;
				});
		},
		vagas() {
			this.$router.push({ name: 'site.contents'})
		},
		profile() {
			this.$router.push({ name: 'candidate.dashboard'})
		}
    },
    
}
/*.v-btn__content {
    display:flex;
    flex-direction:column;
}*/
</script>

<style scoped>
.v-btn__content {
    display:flex;
    flex-direction:column;
}
img {
    object-fit: cover;
}
</style>