<template>
	<v-container fluid fill-height>
		<!-- <v-layout row wrap justify-center align-center> -->
			<!-- <v-flex xs12 md4 dark color="red acent-3"> </v-flex> -->
			<v-row wrap align="center" justify="center" >
				<v-col cols="12" class="text-center">
                    <p class="text-sm-h2 text-md-h1 text--secondary">404!</p>
                    <p class="text-sm-h3 text-md-h2 text--secondary">Página não encontrada!</p>
				</v-col>
			</v-row>
		<!-- </v-layout> -->
	</v-container>
</template>

<script>
export default {
};
</script>

<style scoped>

.red-gugla {
	color: #FF1744;
}

</style>