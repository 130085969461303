<template>
    <v-container>
        <validation-observer
        ref="observer"
        v-slot="{  }"
        >
            <form @submit.prevent="submit">
                <validation-provider
                v-slot="{ errors }"
                name="Cargo"
                rules="required"
                >
                    <v-text-field
                    v-model="experience.cargo"
                    prepend-icon="mdi-work"
                    label="Cargo"
                    :error-messages="errors"
                    />
                </validation-provider>
                <validation-provider
                v-slot="{ errors }"
                name="Empresa"
                rules="required"
                >
                    <v-text-field
                    v-model="experience.empresa"
                    prepend-icon="mdi-work"
                    label="Empresa"
                    :error-messages="errors"
                    />
                </validation-provider>
                <validation-provider
                v-slot="{ errors }"
                name="local"
                rules="required"
                >
                    <v-text-field
                    v-model="experience.local"
                    prepend-icon="mdi-work"
                    label="Local"
                    :error-messages="errors"
                    />
                </validation-provider>
                <validation-provider
                v-slot="{ errors }"
                name="Industria"
                rules="required"
                >
                    <v-autocomplete
                    v-model="experience.industria"
                    :items="industrias"
                    prepend-icon="mdi-work"
                    label="Industria"
                    :error-messages="errors"
                    no-data-text="Nenhuma industria encontrada"
                    />
                </validation-provider>
                <v-row>
                    <v-col cols="6">
                        <v-menu
							ref="menu_inicio"
							v-model="menu_inicio"
							:close-on-content-click="false"
							:return-value.sync="date_inicio"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
								dense
								v-model="experience.inicio"
								label="Inicio"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
                                
								></v-text-field>
							</template>
							<v-date-picker
								dense
								v-model="experience.inicio"
								no-title
								scrollable
							>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu_inicio = false">
									Cancel
								</v-btn>
								<v-btn text color="primary" @click="$refs.menu_inicio.save(date_inicio)">
									OK
								</v-btn>
							</v-date-picker>
						</v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-menu
							ref="menu"
							v-model="menu_fim"
							:close-on-content-click="false"
							:return-value.sync="date_fim"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
								dense
								v-model="experience.fim"
								label="Fim"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
                                :disabled="atual"
								></v-text-field>
							</template>
							<v-date-picker
								dense
								v-model="experience.fim"
								no-title
								scrollable
							>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu_fim = false">
									Cancel
								</v-btn>
								<v-btn text color="primary" @click="$refs.menu.save(date_fim)">
									OK
								</v-btn>
							</v-date-picker>
						</v-menu>
                    </v-col>
                </v-row>
                <v-checkbox
				v-model="atual"
				color="secondary"
				label="Emprego atual"
				/>
                <validation-provider
                v-slot="{ errors }"
                name="Descrição"
                rules="required"
                >
                    <v-textarea
                    v-model="experience.descricao"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-work"
                    label="Descrição"
                    :error-messages="errors"
                    />
                </validation-provider>
                <v-card-actions class="">
                    <v-row>
                        <v-col v-if="isUpdate" md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="update"
                            >Guardar</v-btn>
                        </v-col>
                        <v-col v-else md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="submit"
                            >Adicionar</v-btn>
                        </v-col>
						<v-col>
                            <v-btn
							v-if="isUpdate"
                            text
                            color="error"
                            outlined
                            @click="remove"
                            >apagar</v-btn>
                        </v-col>
                       
                        
                    </v-row>
                </v-card-actions>
            </form>

        </validation-observer>
    </v-container>
</template>
<script>
import { required, digits, email, max, regex, integer } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} não pode estar vazio',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

 extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
		experience: {
			required: false,
			type: Object,
			default() {
				return {
					cargo: '',
					empresa: '',
					local: '',
					industria: '',
					inicio: null,
					fim: null,
					atual: false,
					descricao: ''
				}
			}
		},
        isUpdate: {
            require: false,
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
		menu_inicio: false,
		date_inicio: new Date().toISOString().substr(0, 10),
		date_fim: new Date().toISOString().substr(0, 10),
		menu_fim: false, 
        atual: false,
        industrias: [
            'Administração e Secretariado Cliente',
            'Aquisições e Procurement',
            'Assistente',          
            'Auditoria e Consultoria',
            'Comunicação Social',
            'Construção Civil',
            'Contabilidade e Finanças',
            'Design',
            'Direito e Justiça',
            'Electricidade',
            'Gestão de Projectos',
            'Gestão e Assessoria',
            'Gestão Financeira',
            'Gestor Provincial',
            'Higiene e Segurança',
            'Hotelaria e Turismo',
            'informática e Programação',
            'Manutenção e Técnica',
            'Marketing e Publicidade',
            'Mecânica',
            'Meio Ambiente',
            'Monitoria e Avaliação',
            'Oficial Técnico',
            'Operário',
            'Recursos',
            'Humanos',
            'Relações Públicas',
            'Saúde',
            'Seguros',
            'Serviços Gerais',
            'Serviços Sociais',
            'Supervisão e Coordenação',
            'Topografia e Geologia',
            'Transportes e Logística',
            'Vendas/Comércio'
            


        ]
        
      }
    },
    computed: {
      getCandidate() {
        let candidate = this.$store.state.candidate
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if(this.experience.atual == 1){this.atual = true}
        return candidate
      }

    },
	created() {
        if(this.experience.atual){
            this.atual = true
        }
    },
    methods: {
		submit() {
			if(this.atual){
                this.experience.atual = this.atual
                this.experience.fim = null
            } else {
                this.experience.atual = this.atual
            }
				
			
			this.$store.dispatch('loaderChange', true)
			this.$store.dispatch('experience', this.experience)
			.then(() => {
				this.$router.go()
				this.$store.dispatch('loaderChange', false)
				
			})
			
			
		},
		update() {
			if(this.experience.atual)
				this.experience.fim = null
            
            const data = { 
                data: {
                    cargo: this.experience.cargo,
					empresa: this.experience.empresa,
					local: this.experience.local,
					industria: this.experience.industria,
					inicio: this.experience.inicio,
					fim: this.experience.fim,
					atual: this.experience.atual,
					descricao: this.experience.descricao
                },
                id: this.experience.id
            
            }
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('updateExperience',data)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
        },
		remove(){
			console.log('removed')
            const data = {
                table: 'profissional-experience',
                id: this.experience.id
            }
            //deletData
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('deleteData',data)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
		},
        test() {
            console.log(typeof(this.experience.atual))
        }
      
    },
	filters: {
		formatDate(date) {
			if (date) {
				let newDate = new Date(date);
				let newFormatedDate =
					(newDate.getMonth() + 1) +
					"/" +
					newDate.getFullYear();
				return newFormatedDate;
			}

			return "";
		}
	}
    
}
</script>