<template>
  <v-container>
  <v-card
    flat
    width="auto"
   >
    <v-card-title>Idiomas</v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text v-for="language in getCandidate.language" :key="language.id">
        <v-row wrap>
          <v-col cols="10">
            <p class="text-h6 text--primary">
              {{language.idioma}}
            </p> 
          
            <div>fala - {{language.fala}}</div>
        
            <p>Escrita - <span>{{language.escrita}} </span></p>
          </v-col>
            <v-col cols="2" align-self="center">
				<v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                >

                    <template v-slot:activator="{ on, attrs }">
                        
                            <v-btn v-bind="attrs" v-on="on" depressed rounded text small>
                                <v-avatar >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-avatar>
                            </v-btn>
                            
                    </template>
                    <template v-slot:default="dialog">
                        <v-toolbar
                        color="secondary"
                        dark
                        >
						<v-row>
                            <v-col cols="9">
                                Editar Idioma
                            </v-col>
                            <v-col cols="3">
                                <v-btn color="secondary" @click="dialog.value = false">
                                    <v-icon color="" large>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-col>				
                        </v-row>
						</v-toolbar>
                        <v-card>
                            <language :language="language" :isUpdate="true" :id="language.id"/>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
                  
        </v-row>
     
        
          
          
        <!--<div class="text--primary">
          {{experience.descricao}}
        </div>-->
      <v-divider style=""></v-divider>
    </v-card-text>
    <v-card-actions>
        <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        >

            <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="secondary "
                v-bind="attrs"
                v-on="on"
                text
                >
                    Adicionar Idioma
                </v-btn>     
            </template>
            <template v-slot:default="dialog">
                <v-toolbar
                color="secondary"
                dark
                >
					<v-row>
                        <v-col cols="9">
                            Adicionar Idioma
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="secondary" @click="dialog.value = false">
                                <v-icon color="" large>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-col>				
                    </v-row>
				</v-toolbar>
                <v-card>
                    <language/>
                    <v-card-actions>
                        
                        <v-btn
                        text
                        @click="dialog.value = false"
                        >Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-card-actions>

    </v-card>
    </v-container>
</template>

<script>
import Language from './forms/LanguageForm.vue'
export default {
    components: {Language},
    computed: {
      getCandidate() {
        let candidate = this.$store.state.candidate
        
        return candidate
      }
    },
}
</script>

<style>

</style>