<template>
	<v-container>
		<v-alert v-if="show" type="warning">
            Cancelou com Sucesso a subscrição do newsletter. Você não receberá mais notificações sobre as newsletters do gugla.
        </v-alert>
	</v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
	components: {},

	props: {
		id: {
			required: true,
		},
		email: {
			required: true,
		},
	},

	data() {
		return {
			params: {
				id: this.id,
				email: this.email
			},
            showAlert: false
		};
	},

	created() {
		this.activateUser();
	},

	computed: {
		show(){
            return this.showAlert
        }
	},

	methods: {
		activateUser() {
			// console.log(this.params)
			// this.$store
			// 	.dispatch("activeCandidate", this.params)
			// 	.then((response) => {
			// 		Swal.fire({
			// 			title: "Sucesso!",
			// 			html: `Conta Activada! <br> Por favor use as suas credenciais para aceder à plataforma Gulga Empregos na próxima tela.`,
			// 			icon: "success",
			// 		})
			// 	})
			// 	.catch((error) => {
            //         Swal.fire({
            //             title: "Falha!",
            //             text: "Não foi possível activar a conta! Será redirecionado para as vagas publicadas na Plataforma.",
            //             icon: "error",
            //         })
					
			// 	});
            Swal.fire({
				title: "Cancelar subscrição!",
				html: `Tem certeza de que deseja cancelar a subscrição do newsletter?`,
				icon: "warning",
                confirmButtonText: 'Sim',
                confirmButtonColor: "#FF1744",
                showCancelButton: true,
                cancelButtonText: 'cancelar',
                footer: 'Você não receberá mais notificações sobre as newsletters do gugla.'
			}).then((res) => {
                console.log(this.params)
                if(res.isConfirmed) {
                    this.$store.dispatch('loaderChange', true)
                    this.$store.dispatch("cancelNewsletter", this.params)
                        .then(() => {
                            this.showAlert = true
                            this.$store.dispatch('loaderChange', false)
                        })
                } else if(res.isDenied) {
                    this.$router.push({name: 'site.contents'})
                }
            })
		},
	},
};
</script>

<style scoped>
.red-gugla {
	color: #ff1744;
}
</style>