<template>
	<v-container>
		<v-row justify="center">
			<v-col cols="12">
				<v-row wrap class="mb-5">
					<v-col cols="12" md="10">
						<p class="text-subtitle-1 text-md-h3">
							{{ content.titulo }}
						</p>
						<v-divider class="my-3"></v-divider>
						<div v-if="$vuetify.breakpoint.smAndDown" class="space-betwen">
							<!--<v-avatar size="80" height="auto" v-if="content.image" rounded>
								<img :src="content.image" alt=""/>
							</v-avatar>-->
						<p class="text-subtitle-2 text-md-h6">
						<b>Detalhes:</b>
						</p>
						<table>
							<tr>
								<td>
									Instituição:
								</td>
								<td>
									{{ content.instituicao }} 
								</td>
							</tr>
							<tr>
								<td>
									Local:
								</td>
								<td>
									<span v-if="content.local">
										{{ content.local }} 
									</span>
									<span v-else>
										<span v-for="local in content.locals" :key="local.id" class="mr-1">
											{{local.name}}
										</span>
									</span>
								</td>
							</tr>
							<tr v-if="content.industria">
								<td>
									Categoria:
								</td>
								<td>
									{{ content.industria }} 
								</td>
							</tr>
							<tr>
								<td>
									Publicado:
								</td>
								<td>
									{{ content.created_at }} 
								</td>
							</tr>
							<tr :class="[expired ? 'left-border-expired' : 'left-border-active']">
								<td>
									Validade:
								</td>
								<td>
									{{ content.validade | formatDate }} 
								</td>
							</tr>
						</table>
						<div v-if="content.candidaturas">
							<p>
								<v-btn
								@click="openDialog"
								v-if="!candidaturas"
								class="ml-0"
								tile
								elevation="4"
								dark
								color="#009AAB"
								small> Enviar candidatura</v-btn>
								<v-btn
								v-else
								class="ml-0"
								tile
								elevation="4"
								dark
								color="#009AAB"
								small>Candidatura enviada</v-btn>
							</p>
						</div>
						<v-divider class="my-3"></v-divider>
						</div>
					</v-col>
				</v-row>

				<v-row wrap class="mb-5">
					
					<v-col md="8" cols="12" >
						<v-img v-if="content.anexo_conteudo" >
								<img :src="content.anexo_conteudo" class="anexo" alt=""/>
							</v-img>
							<div v-else class="text-pre-wrap" v-html="content.conteudo_formatado ? content.conteudo_formatado : content.conteudo"></div>
							<div>
								<div v-if="active_candidatar" v-html="candidatura"></div>
								<div v-if="active_candidatar" v-html="dica"></div>
								<div v-if="content.candidaturas">
									<p>
										<v-btn
										@click="openDialog"
										v-if="!candidaturas"
										class="ml-0"
										tile
										elevation="4"
										dark
										color="#009AAB"
										small> Enviar candidatura</v-btn>
										<v-btn
										v-else
										class="ml-0"
										tile
										elevation="4"
										dark
										color="#009AAB"
										small>Candidatura enviada</v-btn>
									</p>
								</div>
							</div>
							<!-- <div v-else>
								<h4>Processo de candidatura</h4>
								<p>
									<ul>
										<li>Para poder se candidatar por favor <router-link :to="{ name: 'candidate.login'}"> acesse a sua conta  </router-link>ou <router-link :to="{ name: 'candidate.register'}">registe-se</router-link>.</li>
									</ul>
								</p>
							</div> -->
							<div>
								<div v-if="$vuetify.breakpoint.smAndDown">
									<div class="share-btn">
										<v-btn icon>
											<br>
											Compartilhar
										</v-btn>
									</div>
									<v-row style="margin-left: 10px;">
										
										<v-row wrap>
											
											<v-col cols="2">
												<ShareNetwork
													network="facebook"
													:url="`https://empregos.gugla.co.mz/contents/${content.id}/show`"
													:title="`Vaga - ${content.titulo}`"
													quote="The hot reload is so fast it\'s near instant. - Evan You"
													hashtags="empregos,jobs,gugla,guglaempregos"
												>
													<br>
														<v-btn
															icon
														>
														<v-icon large dark color="#4267B2"> mdi-facebook </v-icon></v-btn
													>
												</ShareNetwork>
											</v-col>
											<v-col cols="2">
												<ShareNetwork
													network="whatsapp"
													:url="`https://empregos.gugla.co.mz/contents/${content.id}/show`"
													:title="`Vaga - ${content.titulo}`"
													quote="The hot reload is so fast it\'s near instant. - Evan You"
													hashtags="empregos,jobs,gugla,guglaempregos"
												>
													<br>
														<v-btn
															icon
														>
														<v-icon large dark color="green"> mdi-whatsapp </v-icon></v-btn
													>
												</ShareNetwork>
											</v-col>
											<v-col cols="2">
												<ShareNetwork
													network="linkedin"
													:url="`https://empregos.gugla.co.mz/contents/${content.id}/show`"
													:title="`Vaga - ${content.titulo}`"
													quote="The hot reload is so fast it\'s near instant. - Evan You"
													hashtags="empregos,jobs,gugla,guglaempregos"
												>
													<br>
														<v-btn
															icon
														>
														<v-icon large dark color="#0072B1"> mdi-linkedin </v-icon></v-btn
													>
												</ShareNetwork>
											</v-col>
											<v-col cols="2">
												<br>
												<v-btn
												@click="copyUrl(`https://empregos.gugla.co.mz/contents/${content.id}/show`)"
												icon>
													<v-icon dark large>
														mdi-link-variant
													</v-icon>
												</v-btn>
											</v-col>
										</v-row>
										
										
									</v-row>
							<div>
								<div class="share-btn" style="margin-left: 110px; margin-top: 20px;">
									<v-btn icon :href="`mailto:info@gugla.co.mz?subject=Suporte: https://empregos.gugla.co.mz/contents/${content.id}/show`">
										reportar erro ou dificuldade
									</v-btn>
								</div>
							</div>
								</div>
								
							</div>
							
					</v-col>
					<v-col md="4" cols="12" class="hidden-xs-only">
						<div class="sticky-top" >
						<p class="text-subtitle-2 text-md-h6">
							Detalhes
						</p>
						<v-divider class="my-3"></v-divider>
						<div class="" style="margin-left:15px;">
							<v-avatar size="80" height="auto" v-if="content.image" rounded>
								<img :src="content.image" alt=""/>
							</v-avatar>
						</div>
						<table>
							<tr>
								<td>
									Instituição:
								</td>
								<td>
									{{ content.instituicao }} 
								</td>
							</tr>
							<tr>
								<td>
									Local:
								</td>
								<td>
									<span v-if="content.local">
										{{ content.local }} 
									</span>
									<span v-else>
										<span v-for="local in content.locals" :key="local.id" class="mr-1">
											{{local.name}}
										</span>
									</span>
								</td>
							</tr>
							<tr v-if="content.industria">
								<td>
									Categoria:
								</td>
								<td>
									{{ content.industria }} 
								</td>
							</tr>
							<tr>
								<td>
									Publicado:
								</td>
								<td>
									{{ content.created_at }} 
								</td>
							</tr>
							<tr :class="[expired ? 'left-border-expired' : 'left-border-active']">
								<td>
									Validade:
								</td>
								<td>
									{{ content.validade | formatDate }} 
								</td>
							</tr>
						</table>
						<div v-if="content.candidaturas">
							<p>
								<v-btn
								@click="openDialog"
								v-if="!candidaturas"
								class="ml-0"
								tile
								elevation="4"
								dark
								color="#009AAB"
								small> Enviar candidatura</v-btn>
								<v-btn
								v-else
								class="ml-0"
								tile
								elevation="4"
								dark
								color="#009AAB"
								small>Candidatura enviada</v-btn>
							</p>
						</div>
						<div>
							<div class="share-btn" style="margin-left: 110px;">
								<v-btn icon :href="`mailto:info@gugla.co.mz?subject=Suporte: https://empregos.gugla.co.mz/contents/${content.id}/show`">
									reportar erro ou dificuldade
								</v-btn>
							</div>
						</div>
						<div>
							<div class="share-btn">
								<v-btn icon>
									<br>
									Compartilhar
								</v-btn>
							</div>
							<v-row style="margin-left: 10px;">
								
								<v-row wrap>
									
									<v-col cols="2">
										<ShareNetwork
											network="facebook"
											:url="`https://empregos.gugla.co.mz/contents/${content.id}/show`"
											:title="`Vaga - ${content.titulo}`"
											quote="The hot reload is so fast it\'s near instant. - Evan You"
											hashtags="empregos,jobs,gugla,guglaempregos"
										>
											<br>
												<v-btn
													icon
												>
												<v-icon large dark color="#4267B2"> mdi-facebook </v-icon></v-btn
											>
										</ShareNetwork>
									</v-col>
									<v-col cols="2">
										<ShareNetwork
											network="whatsapp"
											:url="`https://empregos.gugla.co.mz/contents/${content.id}/show`"
											:title="`Vaga - ${content.titulo}`"
											quote="The hot reload is so fast it\'s near instant. - Evan You"
											hashtags="empregos,jobs,gugla,guglaempregos"
										>
											<br>
												<v-btn
													icon
												>
												<v-icon large dark color="green"> mdi-whatsapp </v-icon></v-btn
											>
										</ShareNetwork>
									</v-col>
									<v-col cols="2">
										<ShareNetwork
											network="linkedin"
											:url="`https://empregos.gugla.co.mz/contents/${content.id}/show`"
											:title="`Vaga - ${content.titulo}`"
											quote="The hot reload is so fast it\'s near instant. - Evan You"
											hashtags="empregos,jobs,gugla,guglaempregos"
										>
											<br>
												<v-btn
													icon
												>
												<v-icon large dark color="#0072B1"> mdi-linkedin </v-icon></v-btn
											>
										</ShareNetwork>
									</v-col>
									<v-col cols="2">
										<br>
										<v-btn
										@click="copyUrl(`https://empregos.gugla.co.mz/contents/${content.id}/show`)"
										icon>
											<v-icon dark large>
												mdi-link-variant
											</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								
								
							</v-row>
						</div>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-useless-escape */

import Swal from 'sweetalert2'
export default {
	props: {
		content: {
			required: true,
			type: Object,
			default(){
				return {
					id: null,
					titulo: "",
					conteudo_formatado: ""
				}
			}
		},
		recomended_content: {
			required: true,
			type: Array
		}
	},
	data() {
		return {
			active_candidatar: false,
			candidatura: '',
			dica: '',
			googleStructure: '',
			candidatura_show: false

		}
	},
	created() {

	},
	metaInfo() {
		return {
			title: this.content.titulo,
			meta: [
				{property:"og:locale", content:"pt_PT"},
				{property:"og:type", content:"article"},
				{property:"og:site_name", content: "Gugla empregos" },
				{property:"og:title", content: this.content.titulo},
				{property:"og:image", content:"https://gugla.s3.us-east-2.amazonaws.com/public/publicity/45/626503005.gif"},
				{property:"og:url", content:`https://empregos.gugla.co.mz/contents/${this.content.id}/show`},
			]
		}
	},
	methods: {
		applyVacancy(){
			console.log(this.content.id);
		},
		openDialog(){
			if (this.isLoggedIn){
				this.$store.dispatch('changeApplyVagaDialog', {value: true, id: this.content.id})
			}
			else {
				this.$router.push({name: 'candidate.dashboard'})
			}
			
		},
		async copyUrl(url) {
			try {
				await navigator.clipboard.writeText(url)
				Swal.fire({
					title: "Link copiado!",
					showConfirmButton: false,
					timer: 1000
				})
			} catch (error) {
				console.log('cannot copy');
			}
		}
	},
	computed: {
		expired() {
			let today = new Date();
			let deadlineDate = new Date(this.content.validade);

			return today > deadlineDate ? true : false;
		},
		isLoggedIn () {
          return this.$store.getters.isLoggedIn
        },
		isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		},
		candidaturas() {
			const cand_array = this.$store.state.cadidaturas
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			let cand_show = cand_array.includes(this.content.id) ? true : false
			return cand_show
		}
	},

	filters: {
		formatDate: (date) => {
			if (date) {
				let newDate = new Date(date);
				let newFormatedDate =
					newDate.getDate() +
					"-" +
					(newDate.getMonth() + 1) +
					"-" +
					newDate.getFullYear();
				return newFormatedDate;
			}

			return "";
		},
	},
	watch: {
		content(content) {
			if(content?.como_candidatar){
				const como_candidatar = JSON.parse(content.como_candidatar)
				//console.log(como_candidatar)
				this.active_candidatar = true
				if(como_candidatar.method == "Entregar candidatura no endereço físico") {
					//console.log(1)
					this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Endereço: ${como_candidatar.desc}</li></ul></p>`
					if(como_candidatar.dica) {
						this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`
					}
				}
				if(como_candidatar.method == "Aceder o link") {
					this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Através do website <a href="${como_candidatar.desc}" rel="noopener noreferrer" target="_blank">${como_candidatar.desc}</a></li></ul></p>`
					if(como_candidatar.dica) {
						this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`
					}
				}
				if(como_candidatar.method == "Enviar candidatura por email"){
					this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Envie um email para <a href="mailto:${como_candidatar.desc}" rel="noopener noreferrer" target="_blank">${como_candidatar.desc}</a></li></ul></p>`
					if(como_candidatar.dica) {
						this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`
					}
				}
				if(content.candidaturas) {
					this.candidatura = `<h4>Processo de candidatra</h4>`
					if(como_candidatar.dica) {
						this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`
					}
				}
			}
			const locals = content.locals.length > 0 ? content.locals[0].name : content.local
			// const locals = content.locals.length > 0 ? content.locals.map(loc => loc.name).toString() : content.local
			this.googleStructure = `
				{
					"@context": "http://schema.org",
					"@type": "JobPosting",
					"title": "${content.titulo}",
					"description": "${content.conteudo_formatado}",
					"datePosted": "${content.created_at}",
					"validThrough": "${content.validade}",
					"employmentType" : "CONTRACTOR",
					"hiringOrganization": {
						"@type": "Organization",
						"name": "${content.instituicao}",
						"sameAs": "https://empregos.gugla.co.mz",
						"logo": "${content.image}"
					},
					"industry": "${content.industria ? content.industria : 'N/A'}",
					"jobBenefits": "",
					"jobLocation": {
						"@type": "Place",
						"address": {
							"@type": "PostalAddress",
							"streetAddress": "N/A",
							"addressCountry": "MZ",
							"addressLocality": "${locals}",
							"addressRegion": "${locals}"
							"postalCode": "N/A",
						}
					},
					"baseSalary": {
						"@type": "MonetaryAmount",
						"currency": "MZN",
						"value": {
						"@type": "QuantitativeValue",
						"value": "N/A",
						"unitText": "N/A"
					},
					"occupationalCategory": "${content.industria ? content.industria : 'N/A'}",
					"qualifications": "",
					"responsibilities": "",
					"skills": ""
				}
			`;
			// ${content.conteudo.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\"/g, `\\"`)}
			// console.log(content.conteudo.replace(/\n/g, "\\n").replace(/\r/g, "\\r"));
			//var pre = document.createElement('pre')
			//pre.textContent = googleStructure
			//document.body.append(pre)
			//document.body.insertAdjacentElement('beforeend', this.googleStructure)
			
			var title = document.getElementById('title')
			var newScript = document.createElement('script')
			newScript.setAttribute('type', 'application/ld+json')
			var googleJson = document.createTextNode(this.googleStructure)
			newScript.appendChild(googleJson)
			document.body.appendChild(newScript)

			var linkTag = document.createElement('link')
			linkTag.setAttribute('rel', 'canonical')
			linkTag.setAttribute('href', `https://empregos.gugla.co.mz/contents/${content.id}/show`)
			title.parentElement.insertBefore(linkTag, title.nextSibling)
			// document.head.appendChild(linkTag)

			// let newMeta = document.createElement('meta')
			// newMeta.setAttribute('property', 'og:title')
			// newMeta.setAttribute('content', `${content.titulo}`)
			// document.head.appendChild(newMeta)
			// title.parentElement.insertBefore(newMeta, title.nextSibling)
		}
    }
};
</script>

<style scoped>
.anexo {
	width: 100%;
}
.sticky-top {
    position: sticky;
    top: 70px;
}
table, tr, td {
	border: none;
	font-size: 13px;
}
td {
	padding: 0 10px 0 10px;
}
.left-border-expired {
	/* red accent-3 */
	border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
	/* red accent-3 */
	border-bottom: 4px solid #ff1744 !important;
}

.left-border-active {
	/* light-green accent-4 */
	border-left: 4px solid #FCC353 !important;
	/*  */
}

.bottom-border-active {
	border-bottom: 4px solid #FCC353 !important;
}
.share-btn {
	margin: 0 0 0 45px;
}

</style>