<template>
	<div style="background-color: #F0F2F5;">
		<!-- <loading /> -->
		<!--<loading-2 />-->
		<main-nav-candidate />
		<v-main>
			<v-container class="mt-6">
				<router-view></router-view>
			</v-container>
		</v-main>
		<generic-footer />
	</div>
</template>

<script>

import MainNavCandidate from "../header/MainNavSite.vue";
// import Footer from "../layouts/footer/Footer";
import GenericFooter from '../footer/newFooter.vue';

export default {
	components: {
		MainNavCandidate,
		// Footer,
        GenericFooter
	},
};
</script>

<style scoped>
</style>