<template>
    <v-container>
        <v-app-bar app clipped-left color="white">
            <v-toolbar-title>
				<img :src="career.company_logo" height="45" alt="Gugla Empregos"/>
            </v-toolbar-title>
        </v-app-bar>
    </v-container>
</template>

<script>
export default {
    props: {
        career: {
            required: true,
            type: Object
        }
    }
}
</script>

<style>

</style>