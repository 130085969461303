<template>
  <v-app>
      <!--<header-component/>-->
    
   
      
				<router-view></router-view>
		
      
   
    <!--<change-password/>
    
    <Footer/>-->
    <!--<Loader/>-->
 
    
  </v-app>
</template>

<script>

//import loader from './components/loader/Loader.vue'
/*import ChangePassword from './components/candidate/ChangePassword.vue'
import Loader from './components/loader/Loader.vue'
import Footer from './components/footer/footer.vue';
import HeaderComponent from './components/header/HeaderComponent2.vue'
//import axios from 'axios';*/
import Swal from 'sweetalert2'



export default {

  name: 'App',
  component: {},
  /*components: { Footer, HeaderComponent, Loader, ChangePassword},*/
  created: function () {
    
    if(localStorage.getItem('token')){
      this.$store.dispatch('loaderChange', true)
      this.$store.dispatch('me')
      .then(() => {
        this.$store.dispatch('loaderChange', false)
      })
      .catch(err => {
        
        this.$store.dispatch('logout')
        this.$router.push({ name: 'candidate.login'})
        this.$store.dispatch('loaderChange', false)
      })
  }
}
}
</script>
<style scoped>


</style>
