<template>
<!-- eslint-disable vue/no-unused-vars -->
  <form>
      <!-- <v-text-field
			ref="search"
			v-model.lazy.trim="filter"
			label="Pesquisar"
			placeholder="pesquisar"
            aria-autocomplete="off"
            @focus="searchClose = false"
            @blur="searchClose = true"
            prepend-inner-icon="mdi-magnify"
            class="expandig-search mt-1"
            :class="{'closed' : searchClose}"
			append-icon="mdi-briefcase-search"
			@click:append.prevent="searchContent"
			@keypress.enter.prevent="searchContent"
		></v-text-field> -->
        <v-row v-if="searchClosed" wrap align="center" justify="space-between">
            <v-col cols="4">
                <v-btn icon @click="searchClosed=false">
                    <v-icon large>
                        mdi-magnify
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col cols="8">
                <span class="span-color">Filtrar por: </span>
                <v-dialog
                v-model="dialog"
                scrollable
                max-width="400px">
                    <template v-slot:activator="{ on, attrs }">
                        
                        <span class="span-button" v-bind="attrs" v-on="on">LOCAIS</span>
                    </template>
                    <v-card>
                        <v-card-title>Selecione o local</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="height: 400px;">
                            <v-list
                            nav
                            dense
                            v-for="local in locals" :key="local">
                                <v-list-item @click="filterLocal(local)">
                                    <v-list-item-title>{{local}}</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>
                        </v-card-text>
                        
                    </v-card>
                </v-dialog>
                    
                    <span class="span-color"> | </span>
                    <v-dialog
                    v-model="dialogCategory"
                    scrollable
                    max-width="400px">
                        <template v-slot:activator="{ on, attrs }"> 
                            
                            <span class="span-button" v-bind="attrs" v-on="on">CATEGORIAS</span>
                        </template>
                        <v-card>
                            <v-card-title>Selecione a categoria</v-card-title>
                            <v-divider></v-divider>
                            <v-card-text style="height: 400px;">
                                <v-list
                                nav
                                dense
                                v-for="category in apiCategories" :key="category.category_name">
                                    <v-list-item @click="filterCategory(category.category_name)">
                                        <v-list-item-title>{{category.category_name}} ({{category.category_count}})</v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>
                            </v-card-text>
                            
                        </v-card>
                    </v-dialog>
            </v-col>
            
        </v-row>
        <v-row v-if="!searchClosed" wrap align="center" justify="space-between">
            <v-col cols="12">
                <v-text-field
                autofocus
                ref="search"
                v-model.lazy.trim="filter"
                label="Pesquisar"
                placeholder="pesquisar"
                aria-autocomplete="off"
                append-icon="mdi-briefcase-search"
                @click:append.prevent="searchContent"
                @keypress.enter.prevent="searchContent"
                @focus="searchClosed = false"
                @blur="searchClosed = true"
            ></v-text-field>
            </v-col>
        </v-row>
        
  </form>
</template>

<script>
export default {
    props: {
        apiCategories: {
            required: true,
            type: Array
        }
    },
	data() {
		return {
			filter: "",
            searchClosed: true,
            dialog: false,
            dialogCategory: false,
            locals: [
                'Cabo Delgado',
                'Gaza',
                'Inhambane',
                'Manica',
                'Maputo',
                'Nampula',
                'Niassa',
                'Sofala',
                'Tete',
                'Zambeze'
            ],
            categories: [
                'Administração e Secretariado Cliente',
                'Aquisições e Procurement',
                'Assistente',          
                'Auditoria e Consultoria',
                'Comunicação Social',
                'Construção Civil',
                'Contabilidade e Finanças',
                'Design',
                'Direito e Justiça',
                'Electricidade',
                'Gestão de Projectos',
                'Gestão e Assessoria',
                'Gestão Financeira',
                'Gestor Provincial',
                'Higiene e Segurança',
                'Hotelaria e Turismo',
                'informática e Programação',
                'Manutenção e Técnica',
                'Marketing e Publicidade',
                'Mecânica',
                'Meio Ambiente',
                'Monitoria e Avaliação',
                'Oficial Técnico',
                'Operário',
                'Recursos Humanos',
                'Relações Públicas',
                'Saúde',
                'Seguros',
                'Serviços Gerais',
                'Serviços Sociais',
                'Supervisão e Coordenação',
                'Topografia e Geologia',
                'Transportes e Logística',
                'Vendas/Comércio',
                'outro'
            ]
		}
	},
	methods: {
		searchContent(){
			this.$emit("searchContentEvent", this.filter);
			this.filter = "";
		},
        filterLocal(local) {
            this.dialog = false
            this.$emit("searchByLocalContentEvent", local);
        },
        filterCategory(category) {
            this.dialogCategory = false
            this.$emit("searchByCategoryContentEvent", category);
            // console.log(category)
        }
	}
    
				
}
</script>

<style>
    .span-color {
        color: rgb(136, 136, 136);
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 0.85em;
    }
    .span-button {
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 0.85em;
    }
</style>