import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
const opts = {

    theme: {
		themes: {
			light: {
				accent: '#E61F31',
				secondary: '#009AAB',
			},
			dark: {
				accent: '#E61F31',
				secondary: '#009AAB',
			}
		}
    },
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    }
}
export default new Vuetify(opts);
