<template>
    <v-footer inset padless color="white">
        <v-container>
            <v-divider class="mt-4"></v-divider>
				<v-row>
					<v-col cols="12" class="text-center">
						<p class="mt-2 mb-1 text-subtitle-2">
							© {{new Date().getFullYear()}} Gugla. Todos direitos reservados.
						</p>
					</v-col>
				</v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>