<template>
    <div>
        <nav-component :career="career.careerPage" style="margin-bottom: 60px;"/>
        <career-page :career="career.careerPage" :content="career.vagas"/>
        <loader/>
        <footer-component/>
    </div>
</template>

<script>
import axios from 'axios'
import {API} from '../../services/api'
import navComponent from './layout/nav.vue'
import footerComponent from './layout/footer.vue'
import careerPage from './layout/careerPage.vue'
import loader from '../loader/Loader.vue'

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {navComponent, footerComponent, careerPage, loader},
    props: {
        slug: {
            required: true
        }
    },
    data() {
        return {
            career: {
                careerPage: {},
                vagas: []
            }
        }
    },
    created() {
        this.getCarrerPage(this.slug)
    },
    methods: {
        async getCarrerPage(slug) {
            try {
                this.$store.dispatch('loaderChange', true)
                this.career = await (await axios.get(`${API}/guest/careers/${slug}`)).data.data
                this.$store.dispatch('loaderChange', false)
            } catch (error) {
                this.$store.dispatch('loaderChange', false)
                this.$router.push({name: 'site.error404'})
            }
        }
    }
}
</script>

<style>

</style>