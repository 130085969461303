<template>
	<v-container fluid fill-height style="">
		<!-- <v-row wrap justify="center" align-center >
				<v-col cols="12" md="3" >
					Activando o User
				</v-col>
			</v-row> -->
	</v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
	components: {},

	props: {
		id: {
			required: true,
		},
		email: {
			required: true,
		},
	},

	data() {
		return {
			params: {
				id: this.id,
				email: this.email
			}
		};
	},

	created() {
		this.activateUser();
	},

	/*computed: {
		params() {
			return {
				id: this.id,
				email: this.email,
			};
		},
	},
*/
	methods: {
		activateUser() {
			// console.log(this.params)
			this.$store.dispatch('loaderChange', true)
			this.$store
				.dispatch("activeCandidate", this.params)
				.then((response) => {
					// console.log(response.data.access_token)
					const token = response.data.access_token
					localStorage.setItem('token', token)
					this.$store.state.token = token
					this.$router.push({ name: "site.contents" });
					this.$store.dispatch('loaderChange', false);
					// Swal.fire({
					// 	title: "Sucesso!",
					// 	html: `Conta Activada! <br> Por favor use as suas credenciais para aceder à plataforma Gulga Empregos na próxima tela.`,
					// 	icon: "success",
					// 	confirmButtonText: "Ok",
					// 	confirmButtonColor: "#FF1744",
					// }).then(() => {
					// 	this.$router.push({ name: "candidate.login" });
					// });
				})
				.catch((error) => {
                    Swal.fire({
                        title: "Falha!",
                        text: "Não foi possível activar a conta! Será redirecionado para as vagas publicadas na Plataforma.",
                        icon: "error",
                        confirmButtonText: "Ok",
                        confirmButtonColor: "#FF1744"
                    }).then(() => {
                        this.$router.push({ name: "site.contents" });
                    })
					
				});
		},
	},
};
</script>

<style scoped>
.red-gugla {
	color: #ff1744;
}
</style>