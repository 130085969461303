<template>
	<v-container class="mt-8">
		<div>
			<!-- <v-row wrap align="center" justify="center">
				<v-col md="12">
					<template>
						<v-chip x-small label href="https://gugla.co.mz/publicidade.html" dark :color="'green darken-1'">anuncie neste espaço</v-chip>
					</template>
					<publicity/>
				</v-col>
			</v-row> -->
			<v-row align="center">
				<v-col cols="6" md="6" class="pl-0 mb-6">
					
					
					
					<v-btn
						:to="{ name: 'site.contents' }"
						class="ml-0"
						tile
						elevation="4"
						dark
						color="#009AAB"
						small
					>
						<v-icon left small> mdi-arrow-left </v-icon>Vagas</v-btn
					>
				</v-col>
				<v-col v-if="$vuetify.breakpoint.smAndDown" cols="6" md="6" class="text-right">
						<!--<v-avatar size="80" height="auto" v-if="content.image" rounded>
							<img :src="content.image" alt=""/>
						</v-avatar>-->
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="11">
					<content-details-component :content="content" :recomended_content="recomended_content"></content-details-component>
				</v-col>
			</v-row>
			<!-- <v-row >
				<v-col md="12" cols="12" v-if="recomended_content">
					<div>vagas similares</div>
					<v-divider class="my-2"></v-divider>
				</v-col>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="3"  v-for="recomended in recomended_content" :key="recomended.id">
						<v-card
							elevation="0"
							class="mx-auto"
							max-width="344"
						>
							<v-list-item three-line @click="getContent(recomended.id)">
							<v-list-item-content>
								<v-list-item-title>
									{{recomended.titulo}}
								</v-list-item-title>
								<v-list-item-subtitle>{{recomended.industria ? recomended.industria : ""}}</v-list-item-subtitle>
								<v-list-item-subtitle v-if="isMobile">{{recomended.local}}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-avatar v-if="recomended.image" size="60" height="auto" rounded color="">
								<img :src="recomended.image" alt="">
							</v-list-item-avatar>
							</v-list-item>
						</v-card>

					</v-col>
				</v-row>
			</v-row> -->
			<!-- <v-row >
				<v-col md="12" cols="12" v-if="content.recomended_content.data">
					<div>vagas similares</div>
					<v-divider class="my-2"></v-divider>
				</v-col>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="3"  v-for="recomended in content.recomended_content.data" :key="recomended.id">
						<v-card
							elevation="0"
							class="mx-auto"
							max-width="344"
						>
							<v-list-item three-line @click="getContent(recomended.id)">
							<v-list-item-content>
								<v-list-item-title>
									{{recomended.titulo}}
								</v-list-item-title>
								<v-list-item-subtitle>{{recomended.industria ? recomended.industria : ""}}</v-list-item-subtitle>
								<v-list-item-subtitle v-if="isMobile">{{recomended.local}}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-avatar v-if="recomended.image" size="60" height="auto" rounded color="">
								<img :src="recomended.image" alt="">
							</v-list-item-avatar>
							</v-list-item>
						</v-card>

					</v-col>
				</v-row>
			</v-row> -->
			<!-- <v-row justify="start">
				<v-col cols="6" class="pl-0 mb-6 md-4 text-start">
					
					
					
				
						
						<span>Está enfrentando alguma dificuldade?</span>
						<br>
						<v-btn
						:href="`mailto:info@gugla.co.mz?subject=Suporte: https://empregos.gugla.co.mz/contents/${id}/show`"
						class="ml-0"
						tile
						elevation="4"
						dark
						color="#c61584"
						small
					>
						<v-icon left small> mdi-message-settings </v-icon>Suporte</v-btn
					>

					<v-divider class="my-5"></v-divider>
					<span>Verificou alguma ação fraudulenta neste anúncio?</span>
					<br>
					<v-btn
						:href="`mailto:info@gugla.co.mz?subject=Denucia: https://empregos.gugla.co.mz/contents/${id}/show`"
						class="ml-0"
						tile
						elevation="4"
						dark
						color="red acent-3"
						small
					>
						<v-icon left medium> mdi-alert-box </v-icon>Denuciar</v-btn
					>

					
				</v-col>
				
			</v-row> -->
			<!-- <v-row wrap align="center" justify="center">
				<v-col md="12" style="margin-bottom: 40px;">
					<template>
						<v-chip x-small label href="https://gugla.co.mz/publicidade.html" dark :color="'green darken-1'">anuncie neste espaço</v-chip>
					</template>
					<publicity/>
				</v-col>
			</v-row> -->
			
		</div>
	</v-container>
</template>


<script>
import api from '../../services/index';
import contentDetailsComponent from './contentDetailsComponent.vue';
import Publicity from '../publicity/publicity.vue';


export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {contentDetailsComponent, Publicity},
    data(){
		return {
			id: this.$route.params.id,
			content:{},
			recomended_content:[]
			
		}
	},
	computed: {
		candidate() {
			let candidate = this.$store.state.candidate
            return candidate
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		}
	},
	created(){
		this.getContent(this.id);
	},
    methods: { 
		checkUserProfile(){
			//console.log('ok')
			if(this.candidate?.personal_data && this.candidate.personal_data == null){
				this.$store.dispatch('changeCompleteProfile', true)
			}
			else if(this.candidate?.anexos && this.candidate.anexos.length == 0) {
				this.$store.dispatch('changeCompleteProfile', true)
			}
			else if(this.candidate?.education && this.candidate.education.length == 0) {
				this.$store.dispatch('changeCompleteProfile', true)
			}
			else if(this.candidate?.language && this.candidate.language.length == 0) {
				this.$store.dispatch('changeCompleteProfile', true)
			}
		},
		getContent(id){
			
			this.$store.dispatch('loaderChange', true)
			this.$store.dispatch('getContent', {id})
				.then(res => {
					// console.log(res)
					this.content = res.data.data.content;
						this.recomended_content = res.data.data.recomended_content.data

						this.$store.dispatch('loaderChange', false)
						scroll(0,0)
				})
				.catch(err => {
					this.$store.dispatch('loaderChange', false)
					this.$router.push({name: 'site.contents'})
					return err;
				});
			// api.get('guest/contents/' + id).then(
				// res => {
				// 	this.content = res.data.data.content;
				// 	this.recomended_content = res.data.data.recomended_content.data

				// 	this.$store.dispatch('loaderChange', false)
				// 	scroll(0,0)
					/*if(!this.$store.state.token){
						this.$store.dispatch('changeLoginDialog', true)
					} else {
						this.checkUserProfile()
					}*/
					
				// }
			// )
		}
    }
}

</script>

<style>
a {
	text-decoration: none;
}

</style>