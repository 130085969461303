<template>
    <v-container>
        <validation-observer
        ref="observer"
        v-slot="{  }"
        >
            <form @submit.prevent="submit">
                <v-row wrap v-if="imageUploadPreview" >
					<v-col cols="12" md="12" class="text-center">
                        <label for="file" class="inputFile">
						<v-avatar :size="isMobile ? '100' : '65'" for='image'>
							<img :src="imageUploadPreview" alt="" />
                            <v-icon x-large style="position:absolute; top: 0px">mdi-plus</v-icon>
						</v-avatar>
                        </label>
					</v-col>
				</v-row>
                <v-row wrap v-else >
					<v-col cols="12" md="12" class="text-center">
                        <label for="file" class="inputFile">
                            <v-avatar :size="isMobile ? '100' : '65'" for='image'>
                                
                                <img :src="require('../../../../assets/img/user-png.png')" alt="" />
                                <v-icon x-large style="position:absolute; top: 0px">mdi-plus</v-icon>
                            </v-avatar>
                            <div style="color: #838485;">Alterar foto</div>
                        </label>
					</v-col>
				</v-row>
        
      
              <!--<input type="file" name="file"  class="inputfile" />
              <br/>
                <label for="file">Choose a file</label>-->
                <v-file-input
                    label="Fotografia"
                    @change="selectImage"
                    accept="image/*"
                    prepend-icon="mdi-camera"
                    id="file"
                    v-show="hidden"
                ></v-file-input>
                        
                
                    
                <v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							dense
							v-model="personal_data.anoNascimento"
							label="Data de nascimento"
							prepend-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						dense
						v-model="personal_data.anoNascimento"
						no-title
						scrollable
					>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">
                            OK
                        </v-btn>
					</v-date-picker>
				</v-menu>
      
                <validation-provider
                    v-slot="{ errors }"
                    name="Celular"
                    :rules="{
                    required: true,
                    digits: 9,
                    }"
                >
                    <v-text-field
                    v-model="personal_data.celular"
                    :counter="9"
                    :error-messages="errors"
                    prepend-icon="mdi-phone"
                    label="Celular"
                    required
                    ></v-text-field>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="select"
                    rules="required"
                >
                    <v-select
                    v-model="personal_data.genero"
                    :items="items"
                    :error-messages="errors"
                    prepend-icon="mdi-account"
                    label="Genero"
                    data-vv-name="select"
                    required
                    ></v-select>
                </validation-provider>
                <validation-provider
                v-slot="{ errors }"
                name="Profissao"
                rules="required"
                >
                    <v-text-field
                    v-model="personal_data.profissao"
                    prepend-icon="mdi-work"
                    label="Profissão"
                    :error-messages="errors"
                    />
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="Educação"
                    rules="required"
                >
                    <v-select
                    v-model="personal_data.educacao"
                    :items="educacaoList"
                    :error-messages="errors"
                    prepend-icon="mdi-school"
                    label="Educação"
                    data-vv-name="select"
                    required
                    ></v-select>
                </validation-provider>
                <validation-provider
                v-slot="{ errors }"
                name="Endereço"
                rules="required"
                >
                    <v-text-field
                    v-model="personal_data.endereco"
                    prepend-icon="mdi-home"
                    label="Endereço"
                    :error-messages="errors"
                    />
                </validation-provider>
                <!--<validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="checkbox"
                >
                    <v-checkbox
                    v-model="checkbox"
                    :error-messages="errors"
                    value="1"
                    label="Option"
                    type="checkbox"
                    required
                    ></v-checkbox>
                </validation-provider>-->
      
                <v-btn
                    class="mr-4"
                    type="submit"
                    color="secondary"
                    
                >
                    Guardar
                </v-btn>
            </form>

        </validation-observer>
    </v-container>
</template>
<script>
    import { required, digits, email, max, regex, integer } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Email must be valid',
    })
export default {
    components: {ValidationObserver, ValidationProvider},
    props: {
        personal_data: {
            required: false,
            type: Object,
            default() {
                return {
                    celular: null,
                    anoNascimento: null,
                    genero: '',
                    profissao: '',
                    educacao: '',
                    image: '',
                    endereco: ''
                }
            }
        },
        is_update: {
            require: false,
            type: Boolean,
            default: false
        }
        
    },
    
    data() {
        return {
            name: '',
            //phoneNumber: '',
            //genero: null,
            items: [
                'Masculino',
                'Femenino',
            ],
            educacaoList:[
                'Nível Básico',
                'Nível Médio',
                'Técnico Médio',
                'Licenciatura',
                'Pós-Graduação',
                'Mestrado',
                'Bacharelato',
                'Doutoramento'
            ],
            //anoNascimento: null,
            //profissao: '',
            checkbox: null,
            //image: null,
            //endereco: '',
            //educacao: '',
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            hidden: false
        }
    },
    methods: {
        selectImage(file) {
            
           
			this.personal_data.image = file
			//this.previewImage = URL.createObjectURL(this.currentImage);
        },
        submit () {
			

			const formData = new FormData()

			formData.append('celular', Number(this.personal_data.celular))
			formData.append('anoNascimento', this.personal_data.anoNascimento)
			formData.append('genero', this.personal_data.genero)
			formData.append('profissao', this.personal_data.profissao)
			formData.append('educacao', this.personal_data.educacao)
			if(this.personal_data.image != null){
                formData.append('image', this.personal_data.image)
			}
			formData.append('endereco', this.personal_data.endereco)
		
			this.$store.dispatch('loaderChange', true)
			this.$store.dispatch('personalData', formData)
			.then(res => {
			
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
			})
			console.log(this.formData)
			this.$refs.observer.validate()
		},
    },
    computed: {
            
        imageUploadPreview() {
            if(this.is_update){
                return this.personal_data.image
            } else {
                if (this.personal_data.image) {
                    return URL.createObjectURL(this.personal_data.image);
                } 
                return '';
            }
                
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndUp ? true : false;
            }
            /*getCandidate() {
                let candidate = this.$store.state.candidate
                if(candidate){
                this.phoneNumber = candidate.personal_data.celular // eslint-disable-line
                this.anoNascimento = candidate.personal_data.anoNascimento // eslint-disable-line
            this.image = candidate.personal_data.image // eslint-disable-line

            }
            return candidate
        }*/
    },
    formatDate(date) {
			if (date) {
				let newDate = new Date(date);
				let newFormatedDate =
					(newDate.getMonth() + 1) +
					"/" +
					newDate.getFullYear();
				return newFormatedDate;
			}

			return "";
		}
    
}
</script>
<style scoped>
.inputFile {
    cursor: pointer;
}
</style>