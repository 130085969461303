<template>
  <div >
        <v-card class="mx-auto" max-width="1000" tile>
            <!--<v-img height="200" src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"></v-img>-->
            <v-row wrap style="margin:2.5%; top: 0px">
                    <v-col sm="10" md="9">
                        <v-list-item>
                        <v-list-item-avatar :size="isMobile ? '100' : '65'" >
                            <img v-if="image" 
                                :src="getCandidate.personal_data.image"
                            />
                            <img
                                v-else
                                src="../../../assets/img/user-png.png"
                                alt=""
                            >
                    
                            
                            </v-list-item-avatar>
                            <v-list-item-content style="margin-top: 15px;">
                            <v-list-item-title class="title" style="margin-top:20px;">{{getCandidate.name}} {{getCandidate.last_name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="data">{{getCandidate.personal_data.profissao}}
                                
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                               
                                
                                <span v-if="data"> {{getCandidate.personal_data.endereco}}</span>
                               
                                
                               
                                
                               
                                
                            </v-list-item-subtitle>
                            
                            <v-list-item-subtitle>
                                <v-list-item-subtitle>
                                     <v-dialog
                                    transition="dialog-bottom-transition"
                                    max-width="600"
                                >
                                
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip small light outlined color="primary"
                                    v-bind="attrs"
                                    v-on="on">
                                        <span> informação pessoal</span>   
                                    </v-chip>
                                </template>
                                <template v-slot:default="dialog">
                                    <v-card v-if="personal_data">
                                        <v-toolbar
                                        color="secondary"
                                        dark
                                        >
                                            <v-row>
                                                <v-col cols="9">
                                                    Informação Pessoal
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-btn color="secondary" @click="dialog.value = false">
                                                        <v-icon color="" large>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>				
                                            </v-row>
                                        </v-toolbar>

                                        <personal-form v-if="data" :personal_data="getCandidate.personal_data" :is_update="true"/>
                                        
                                        <personal-form v-else/>
                                        
                                    </v-card>
                                    <v-card v-else>
                                        <v-toolbar
                                        color="secondary"
                                        dark
                                        >
                                            <v-row>
                                                <v-col cols="10">
                                                    Informação Pessoal
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-btn color="secondary" @click="dialog.value = false">
                                                        <v-icon color="" large>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>				
                                            </v-row>
                                        </v-toolbar>
                                        
                                        <personal-info/>
                                        <v-card-actions class="">
                                            
                                        </v-card-actions>
                                    </v-card>
                                    </template>
                                </v-dialog>
                                </v-list-item-subtitle>
                            </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item @click.prevent="logout" color="red" v-if="isMobile" style="margin-left: 30px;">
                            <v-list-item-icon>
                                <v-icon>mdi-logout</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item> -->


                    </v-col>
                    <v-col sm="10" md="3" align-self="center">
                        <!-- <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                tile
                                color="secondary"
                                v-bind="attrs"
                                v-on="on"
                                small
                                >
                                    Configurações
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar
                                    color="secondary"
                                    dark
                                    >
                                        <v-row>
                                            <v-col cols="10">
                                                Configurações da conta
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn color="secondary" @click="dialog.value = false">
                                                    <v-icon color="" large>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>				
                                        </v-row>
                                    </v-toolbar> -->
                                    <v-list>
                                        <!-- <v-list-item :to="{name: 'candidate.chagepassword'}">
                                            <v-list-item-icon>
                                                <v-icon>mdi-form-textbox-password</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Alterar Senha</v-list-item-title>
                                        </v-list-item> -->
                                        
                                            <!--<v-list-item-icon>
                                                <v-icon>mdi-bell</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Notificações</v-list-item-title>-->
                                            <!-- <v-dialog v-if="getCandidate.id" v-model="dialog_newsletters" transition="=dialog-top-transition" max-width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-list-item v-bind="attrs" v-on="on">
                                                        <v-list-item-icon>
                                                            <v-icon>mdi-bell</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Notificações</v-list-item-title>
                                                    </v-list-item>
                                                    
                                                </template>
                                                <template>
                                                    <v-form @submit.prevent="onSubmitReceiveNewsletter">
                                                        <v-card>
                                                        <v-toolbar
                                                        color="secondary"
                                                        dark
                                                        >
                                                        Activação de Notificações
                                                        </v-toolbar>
                                                        <v-card-text>
                                                            <p class="text-body-2 text-md-body-1">
                                                                Seja o primeiro a saber sobre novas oportunidades de empregos. Receba notificações sobre as novas vagas publicadas no Gugla Empregos
                                                                .
                                                            </p>
                                                            
                                                            <v-checkbox
                                                            v-model="newsletter.receive_newsletter"
                                                            color="secondary"
                                                            label="Desejo receber as notificações das vagas publicadas."
                                                            >

                                                            </v-checkbox>
                                                        </v-card-text>
                                                        <v-card-actions>						
                                                            <v-btn text type="submit">Submeter</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                    </v-form>
                                                </template>
                                            </v-dialog> -->

                                            <!-- <v-dialog v-else v-model="dialog_newsletters_invite" transition="=dialog-top-transition" max-width="600">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item v-bind="attrs" v-on="on">
                                                        <v-list-item-icon>
                                                            <v-icon>mdi-bell</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Notificações</v-list-item-title>
                                                </v-list-item>
                                                
                                            </template>
                                            <template>
                                                    <v-card>
                                                    <v-toolbar
                                                    color="secondary"
                                                    dark
                                                    >
                                                    Activação de Notificações
                                                    </v-toolbar>
                                                    <v-card-text>
                                                        <p class="text-body-2 text-md-body-1" style="margin-top: 20px">
                                                            Seja o primeiro a saber sobre novas oportunidades de empregos. Receba notificações sobre as novas vagas publicadas no Gugla Empregos.
                                                        </p>
                                                        <p class="text-body-2 text-md-body-1">
                                                            Por favor registe-se no Gugla Empregos pelo <router-link :to="{ name: 'candidate.login'}" class="secondary--text text-decoration-none"> link </router-link> para activar as Notificações.
                                                        </p>
                                                    </v-card-text>
                                                    <v-card-actions>						
                                                         <v-btn text type="submit">Submeter</v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text @click="dialog_newsletters_invite = false">Fechar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog> -->
                                       <!-- <v-list-item @click.prevent="logout" v-if="!isMobile">
                                            <v-list-item-icon>
                                                <v-icon>mdi-logout</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Logout</v-list-item-title>
                                        </v-list-item> -->
                                    </v-list>
                                <!-- </v-card>
                            </template>
                        </v-dialog> -->
                    </v-col>   
            </v-row>
        </v-card>
        
        <v-spacer></v-spacer>
        <v-card class="mx-auto" max-width="1000" tile>
            <v-row wrap style="margin:2.5%; top: 0px">
                <notification :newsletter="newsletter.receive_newsletter"/>
            </v-row>
        </v-card>
        <v-card class="mx-auto" max-width="1000" tile>
            <v-row wrap style="margin:2.5%; top: 0px">
                <profissional-experience/>
            </v-row>
        </v-card>
        <v-card class="mx-auto" max-width="1000" tile>
            <v-row wrap style="margin:2.5%; top: 0px">
                <education/>
            </v-row>
        </v-card>
        <v-card class="mx-auto" max-width="1000" tile>
            <v-row wrap style="margin:2.5%; top: 0px">
                <language/>
            </v-row>
        </v-card>
        <v-card class="mx-auto" max-width="1000" tile>
            <v-row wrap style="margin:2.5%; top: 0px">
                <anexo/>
            </v-row>
        </v-card>
        <v-card class="mx-auto" max-width="1000" tile>
            <v-row style="margin:2.5%; top: 0px">
                <v-container>    
                    <v-card flat width="auto">
                        <v-card-text>
                            <v-list>
                                <v-list-item :to="{name: 'candidate.chagepassword'}">
                                    <v-list-item-icon>
                                        <v-icon>mdi-form-textbox-password</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Alterar Senha</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.prevent="logout">
                                    <v-list-item-icon>
                                        <v-icon>mdi-logout</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Logout</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-row>
        </v-card>
        
        <loader/>
  </div>
</template>

<script>
import Notification from './notificationComponent.vue'
import ProfissionalExperience from './ProfissionalExperienceComponent.vue'
import PersonalForm from './forms/PersonalInformationForm.vue'
//import PersonalInfo from './PersonalInfoComponent.vue'
import Education from './EducationComponent.vue'
import Language from './LanguageComponent.vue'
import Anexo from './AnexoComponent.vue'
import Loader from '../../loader/Loader.vue'
import Swal from 'sweetalert2'
export default {
    components: {PersonalForm ,ProfissionalExperience, Language, Education, Anexo,Loader, Notification},
    data() {
        return {
            image: null,
            personal_data: {},
            data: false,
            dialog_newsletters_invite: false,
            dialog_newsletters: false,
			newsletter: {
				receive_newsletter: false,
			},
        }
    },
    computed: {
       
        getCandidate() {
			let candidate = this.$store.state.candidate
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if(candidate.personal_data){this.image = candidate.personal_data.image}
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if(candidate.personal_data){this.data = true}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if(candidate){this.newsletter.receive_newsletter = candidate.receive_newsletter}
			return candidate
        },
        url() {
            if (!this.image) return;
            return URL.createObjectURL(this.image);
        },
        isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		}
        
        
		
    },
    methods: {
        logout () {
			this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('logout')
            .then(() => {
				this.$router.push({ name: 'candidate.login'})
				this.$store.dispatch('loaderChange', false)
            })
        },
        onSubmitReceiveNewsletter() {
			/*if(this.newsletter.receive_newsletter == true){
				this.newsletter.receive_newsletter = 1
			} else {
				this.newsletter.receive_newsletter = 0
			}*/
			this.$store
				.dispatch("activeNewsletter", this.newsletter)
				.then((response) => {
					if(this.newsletter.receive_newsletter) {
						Swal.fire({
						title: "Notificações",
						html: `Activou o serviço de notificação por email.<br/> Passará a Receber notificações sobre as novas vagas publicadas no Gugla Empregos.`,
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF4500",
					})
					}else{
						Swal.fire({
						title: "Notificações",
						html: `Desactivou o serviço de notificação por email.`,
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF4500",
					})
					}
					this.dialog_newsletters = false;
				});
		},
    }
}
</script>

<style scoped>
img {
    object-fit: cover;
}

</style>