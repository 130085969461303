<template>
    <v-container>
        <validation-observer
        ref="observer"
        v-slot="{  }"
        >
            <form @submit.prevent="submit">
                <validation-provider
                v-slot="{ errors }"
                name="instituição/escola"
                rules="required"
                >
                    <v-text-field
                    v-model="education.instituicao"
                    prepend-icon="mdi-job"
                    label="Intituição ou Escola"
                    :error-messages="errors"
                    />
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="grau"
                    rules="required"
                >
                    <v-select
                    v-model="education.grau"
                    :items="grauList"
                    :error-messages="errors"
                    prepend-icon="mdi-none"
                    label="Grau"
                    data-vv-name="select"
                    required
                    ></v-select>
                </validation-provider>
                <validation-provider
                v-slot="{ errors }"
                name="curso"
                rules="required"
                >
                    <v-text-field
                    :disabled='disableCurso'
                    v-model="education.curso"
                    prepend-icon="mdi-none"
                    label="Curso"
                    :error-messages="errors"
                    />
                </validation-provider>
                
                <validation-provider
                    v-slot="{ errors }"
                    name="estado"
                    rules="required"
                >
                    <v-select
                    v-model="education.estado"
                    :items="estadoList"
                    :error-messages="errors"
                    prepend-icon="mdi-none"
                    label="Estado"
                    data-vv-name="select"
                    required
                    ></v-select>
                </validation-provider>
                <v-card-actions class="">
                    <v-row>
                        <v-col v-if="isUpdate" md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="update"
                            >Guardar</v-btn>
                        </v-col>
                        <v-col v-else md="3" sm="6">
                            <v-btn
                            text
                            color="secondary"
                            outlined
                            @click="submit"
                            >Adicionar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
							v-if="isUpdate"
                            text
                            color="error"
                            outlined
                            @click="remove"
                            >apagar</v-btn>
                        </v-col>
                        
                    </v-row>
                    
                
                
                </v-card-actions>
            </form>

        </validation-observer>

    </v-container>
</template>

<script>
    import { required, digits, email, max, regex, integer } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Email must be valid',
    })
export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
        education: {
            required: false,
            type: Object,
            default() {
                return {
                    instituicao: '',
                    curso: '',
                    grau: '',
                    estado: '',

                }
            }
        },
        isUpdate: {
            require: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            grauList: [
                'Nível Básico',
                'Nível Médio',
                'Técnico Médio',
                'Licenciatura',
                'Pós-Graduação',
                'Mestrado',
                'Bacharelato',
                'Doutoramento'
            ],
            estadoList: [
                'Concluido',
                'Cursando',
                'Finalista',
                'Interropido'
            ]
        }
    },
    methods: {
        submit() {
            this.$store.dispatch('loaderChange', true)
            if(this.education.grau == 'Nível Básico' || this.education.grau == 'Nível Médio'){
                this.education.curso = null
            }
            this.$store.dispatch('education', this.education)
            .then(() => {
                    this.$store.dispatch('loaderChange', false)
                    this.$router.go()
                
            })
      },
      update() {
            
            const data = { 
                data: {
                    instituicao: this.education.instituicao,
                    curso: this.education.curso,
                    grau: this.education.grau,
                    estado: this.education.estado,

                },
                id: this.education.id
            
            }
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('updateEducation',data)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
        },
        remove(){
			console.log('removed')
            const data = {
                table: 'education',
                id: this.education.id
            }
            //deletData
            this.$store.dispatch('loaderChange', true)
            this.$store.dispatch('deleteData',data)
            .then(() => {
                this.$store.dispatch('loaderChange', false)
                this.$router.go()
                
            })
		}
    },
    computed: {
        disableCurso() {
            if(this.education.grau == 'Nível Básico' || this.education.grau == 'Nível Médio'){
                return true
            }
            return false
        }
    }

}
</script>

<style>

</style>