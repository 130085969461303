var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":"instituição/escola","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-job","label":"Intituição ou Escola","error-messages":errors},model:{value:(_vm.education.instituicao),callback:function ($$v) {_vm.$set(_vm.education, "instituicao", $$v)},expression:"education.instituicao"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"grau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.grauList,"error-messages":errors,"prepend-icon":"mdi-none","label":"Grau","data-vv-name":"select","required":""},model:{value:(_vm.education.grau),callback:function ($$v) {_vm.$set(_vm.education, "grau", $$v)},expression:"education.grau"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.disableCurso,"prepend-icon":"mdi-none","label":"Curso","error-messages":errors},model:{value:(_vm.education.curso),callback:function ($$v) {_vm.$set(_vm.education, "curso", $$v)},expression:"education.curso"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.estadoList,"error-messages":errors,"prepend-icon":"mdi-none","label":"Estado","data-vv-name":"select","required":""},model:{value:(_vm.education.estado),callback:function ($$v) {_vm.$set(_vm.education, "estado", $$v)},expression:"education.estado"}})]}}],null,true)}),_c('v-card-actions',{},[_c('v-row',[(_vm.isUpdate)?_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-btn',{attrs:{"text":"","color":"secondary","outlined":""},on:{"click":_vm.update}},[_vm._v("Guardar")])],1):_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-btn',{attrs:{"text":"","color":"secondary","outlined":""},on:{"click":_vm.submit}},[_vm._v("Adicionar")])],1),_c('v-col',[(_vm.isUpdate)?_c('v-btn',{attrs:{"text":"","color":"error","outlined":""},on:{"click":_vm.remove}},[_vm._v("apagar")]):_vm._e()],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }