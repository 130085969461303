/* eslint-disable vue/no-unused-components */
<template>
    <v-app>
      <header-component/>
    
    <v-main>
				<!-- <router-view style="max-width:1161px;"></router-view> -->
        <!-- <move/> -->
        <!-- <login-dialog/>
        <complete-profile/>
        <apply-vaga-dialog/> -->
        <v-row wrap>
          <v-col style="margin-top: 100px;" cols="12">
            <v-container fill-height style="heigth: 100vh;">
              <v-row wrap justify="center" align="center">
                <v-col cols="12" md="6">
                  <v-row wrap>
                    <v-col cols="12">
                      <v-img
                        :src="
                          require('../assets/img/vagas.png')
                        "
                        max-width="150"
                        max-height="150"
                        alt="Gugla Empresas"
                      >
                      </v-img>
                      <h4>
                        Estamos nos mudando. Você será redirecionado em {{ countDown }} segundos, caso não seja redirecionado clique no botão a baixo.
                      </h4>
                      <h4>

                        Encontre-nos em <a href="https://vaga.co.mz">https://vaga.co.mz</a>
                      </h4>
                      <v-btn
                      color="#009AAB"
                      dark
                      href="https://vaga.co.mz"
                      >
                        ir ao vaga
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- <v-row wrap>
                    <v-col cols="12">
                      <p class="font-weight-bold" style="padding: 10px">
                        Em casos de dificuldade contacte:
                      </p>
                      <p style="padding: 10px">
                        <v-icon color="#c61584">
                          mdi-email
                        </v-icon>
                        info@gugla.co.mz
                      </p>
                    </v-col>
                  </v-row> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
    </v-main>

    <loader/>
    
    <Footer/>
  
  </v-app>
</template>

<script>

import Loader from '../components/loader/Loader.vue'
import Footer from '../components/footer/newFooter.vue';
// import move from '../components/candidate/dialogs/loginDialog.vue'
import loginDialog from '../components/candidate/dialogs/move.vue'
import completeProfile from '../components/candidate/dialogs/completeProfile2.vue'
import applyVagaDialog from '../components/candidate/dialogs/applyVagaDialog.vue'
import HeaderComponent from '../components/header/MainNavSite.vue'
import Swal from 'sweetalert2';
import api from '../services/index.js';
export default {
  components: { Footer, HeaderComponent, Loader/**, loginDialog, completeProfile, applyVagaDialog */},
  data() {
    return {
      countDown: 5
    }
  },
  created() {
    this.countDownTimer()
    // this.getPublicity()
    
    // setTimeout(this.active, 20000)
    // Swal.fire({
    //             title: 'Estamos nos mudando',
    //             html: `<p>Encontre-nos em https://vaga.co.mz <br/> em 5s será redirecionado</p>`,
    //             icon: 'warning',
    //             confirmButtonText: 'Ir para vaga',
    //             confirmButtonColor: '#FF1744'
    //         }).then((result) => {
    //           if (result.isConfirmed) {
    //             window.open("https://vaga.co.mz")
    //           }
    //         })
  },
  methods: {
    countDownTimer () {
      console.log("ok");
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                } else {
                  const win = window.open("https://vaga.co.mz").focus()
                    // this.$router.push('https://vaga.co.mz')
                }
            },
    checkUserProfile(){
      if(this.candidate?.personal_data && this.candidate.personal_data == null){
        this.$store.dispatch('changeCompleteProfile', true)
      }
      else if(this.candidate?.anexos && this.candidate.anexos.length == 0) {
        this.$store.dispatch('changeCompleteProfile', true)
      }
      else if(this.candidate?.education && this.candidate.education.length == 0) {
        this.$store.dispatch('changeCompleteProfile', true)
      }
      else if(this.candidate?.language && this.candidate.language.length == 0) {
        this.$store.dispatch('changeCompleteProfile', true)
      }
    },
    active() {
      if(!this.$store.state.token){
        this.$store.dispatch('changeLoginDialog', true)
      } else{
        this.checkUserProfile()      
      }
    },
    getPublicity() {
       api.get(`/guest/publicity/1`)
       .then(res => {
        //  console.log(res.data.data)
         this.$store.dispatch('getPublicity', res.data.data)
       })
    }
  },
  computed: {
    candidate() {
            let candidate = this.$store.state.candidate
            return candidate
        },
    isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		}
  },

}
</script>

<style>

</style>