import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import firebaseConfig from './services/firebase'
import VueSocialSharing from 'vue-social-sharing'
require('./services/errorHandler');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Error handling




firebase.initializeApp(firebaseConfig)
firebase.analytics();

Vue.config.productionTip = false
Vue.use(VueSocialSharing);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

const token = localStorage.getItem('token')

if (token) {
  //Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
