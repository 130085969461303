const firebaseConfig = {
    apiKey: "AIzaSyBJ-YX4HL_fCW0QANG_1NrrMOOiqY8sryA",
    authDomain: "vaga-beadb.firebaseapp.com",
    projectId: "vaga-beadb",
    storageBucket: "vaga-beadb.appspot.com",
    messagingSenderId: "614249756115",
    appId: "1:614249756115:web:64edacdb91f7839d116b26",
    measurementId: "G-S9R0RWKB0X"
  };

  export default firebaseConfig;