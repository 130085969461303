<template>
    <v-dialog
		v-model="applyVagaDial"
		persistent
		width="500"
		>
			<v-card style="max-height: 500px; overflow: hidden;">
				<v-card-title class="text-h5 accent">
					<span style="color:white;">
                        Enviar candidatura
                    </span>
				</v-card-title>
				<v-card-text>
				</v-card-text>
				<v-card-text>
						<span>&bull;Atualize a informação do seu <span class="profile" @click="goToProfile">perfil</span> e aumente as chances de se destacar.</span><br>
						<span>Veja abaixo a informação em falta:</span>
						<span>
							<ul>
								<li style="list-style: none;" v-for="field in emptyFieldsObjects" :key="field.name">
									<v-icon>
										{{field.icon}}
									</v-icon>
									{{field.name}};
								</li>
								
							</ul>
						</span>
                        <v-btn
                        color="primary"
                        text
                        @click="goToProfile">
                            Atualizar o perfil
                        </v-btn><br>
				</v-card-text>
            <v-card-text>
                <v-progress-linear
                :value="total_keyEmpty_percent"
                color="accent"
                height="25"
                >
                    <template  v-slot:default="{  value }">
                        <strong>{{ Math.ceil(value) }}% preenchido</strong>
                    </template>
                </v-progress-linear>
                    <v-card-actions>
                        <v-btn
                        color="secondary"
                        @click="applyVacancy"
                        >
                            Enviar Candidatura
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="()=>{this.$store.dispatch('changeApplyVagaDialog', false)}"
                        >
                            Cancelar
                        </v-btn>
                </v-card-actions>
            </v-card-text>
			</v-card>
		</v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import {BASE_URL} from "../../../services/config";
import axios from 'axios'
export default {
    data() {
        return {
			total_keyEmpty_percent: 0,
            emptyFields: [],
            emptyFieldsObjects: []
        }
    },
    computed: {
        applyVagaDial() {
            return this.$store.getters.applyVaga
        },
        isLoggedIn () {
          return this.$store.getters.isLoggedIn
        },
        candidate() {
            let candidate = this.$store.state.candidate
            return candidate
        },
        isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		}
    },
    methods: {
        goToProfile() {
            this.$store.dispatch('changeApplyVagaDialog', {value: false, id: this.$store.state.vaga})
            this.$router.push({name: 'candidate.dashboard'})
        },
        applyVacancy() {
            this.$store.dispatch('changeApplyVagaDialog', {value: false, id: this.$store.state.vaga})
            this.$store.dispatch('loaderChange', true)
            const content_id = this.$store.state.vaga
            console.log(this.$store.state.vaga);
            const config = {
                headers: {
                    Authorization: `Bearer ${this.$store.state.token}`,
                }
            }
            axios.post(`${BASE_URL}apply-vacancy`, {content_id}, config)
                .then(res => {
                    Swal.fire({
                        title: "Sucesso!",
                        text: "A sua candidatura foi enviada com sucesso.",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#E61F31"
                    }).then(() => {
                        this.$store.dispatch('me')
                    })
                })
                .finally(() => this.$store.dispatch('loaderChange', false))

        }
    },
    watch: {
        candidate(user) {
            let total_semInfo = 0
            this.emptyFieldsObjects = []
            if(!user.personal_data){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Informação pessoal')
                this.emptyFieldsObjects.push({name: 'Informação pessoal', icon: 'mdi-account-details'})
            }
            if(user.education == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Habilitações literárias')
                this.emptyFieldsObjects.push({name: 'Habilitações literárias', icon: 'mdi-school'})
            }
            if(user.language == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Idiomas')
                this.emptyFieldsObjects.push({name: 'Idiomas', icon: 'mdi-message-bulleted'})
            }
            if(user.profissional_experience == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Experiencia Profissiona')
                this.emptyFieldsObjects.push({name: 'Experiência Profissional', icon: 'mdi-briefcase'})
            }
            if(user.anexos == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Anexos (Documentos)')
                this.emptyFieldsObjects.push({name: 'Anexos (Documentos)', icon: 'mdi-file-multiple'})
            }
            this.total_keyEmpty_percent =100 - (total_semInfo*100)/5;
        }
    }
}
</script>

<style>
.profile {
    color: cornflowerblue;
}
.profile:hover {
    cursor: pointer;
}
</style>