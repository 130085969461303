<template>
  <v-container>
		<v-card
		flat
		width="auto">
    <v-card-title>
		<v-row>
			<v-col cols="10">
				Experiência Profissional
			</v-col>
			
		</v-row>
		
	</v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text v-for="experience in getCandidate.profissional_experience" :key="experience.id">
		<v-row>
			<v-col cols="10">
				<p class="text-h6 text--primary">
				{{experience.empresa}}
				</p>
				<div>{{experience.cargo}}</div>
				
				<p>
					<span>{{experience.inicio | formatDate}} </span>
						-
					<span v-if="experience.atual">Atual</span>
					<span v-else> {{experience.fim | formatDate}}</span>
				</p>
			</v-col>
			<v-col cols="2" align-self="center">
				<v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                >

                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" depressed rounded text small>
							<v-avatar >
								<v-icon>
									mdi-pencil
								</v-icon>
							</v-avatar>
						</v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-toolbar
                        color="secondary"
                        dark
                        >
							<v-row>
								<v-col cols="9">
									Experiência Profissional
								</v-col>
								<v-col cols="3">
									<v-btn color="secondary" @click="dialog.value = false">
										<v-icon color="" large>
											mdi-close
										</v-icon>
									</v-btn>
								</v-col>
								
							</v-row>
						</v-toolbar>
                        <v-card>
                            <profissional-experience :experience="experience" :isUpdate="true"/>
								<v-card-actions>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
			</v-col>
		
		</v-row>
          
          
        <!--<div class="text--primary">
          {{experience.descricao}}
        </div>-->
		<v-divider style=""></v-divider>
    </v-card-text>

    <v-card-actions>

		<v-dialog
		transition="dialog-bottom-transition"
		max-width="600"
		>

      <template v-slot:activator="{ on, attrs }">
			<v-btn
			color="secondary "
			v-bind="attrs"
			v-on="on"
			text
			>
			Adicionar Experiencia
			</v-btn>     
      </template>
      <template v-slot:default="dialog">
        <v-toolbar
			color="secondary"
			dark
        >
			<v-row>
				<v-col cols="9">
					Experiência Profissional
				</v-col>
				<v-col cols="3">
					<v-btn color="secondary" @click="dialog.value = false">
						<v-icon color="" large>
							mdi-close
						</v-icon>
					</v-btn>
				</v-col>				
			</v-row>
		</v-toolbar>
        <v-card>
			<v-container> 
				<profissional-experience/>
			</v-container>  
        </v-card>
      </template>
		</v-dialog>
      
    </v-card-actions>
	</v-card>
  </v-container>
</template>
<script>
import ProfissionalExperience from './forms/ProfissionalExperienceForm.vue'

  export default {
    components: {

		ProfissionalExperience
    },
    

    computed: {
      getCandidate() {
        let candidate = this.$store.state.candidate
        
        return candidate
      }
    },

    methods: {

    },
	filters: {
		formatDate(date) {
			if (date) {
				let newDate = new Date(date);
				let newFormatedDate =
					(newDate.getMonth() + 1) +
					"/" +
					newDate.getFullYear();
				return newFormatedDate;
			}

			return "";
		}
	}

  }
</script>