<template>
    <v-container>
        <v-row wrap style="margin-top: 40px; margin-bottom: 80px;">
            <v-col cols="12" md="4" v-for="career in careers" :key="career.id">
                <careers-card :career="career"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import {API} from '../../services/api'
import careersCard from './careersCard.vue'
export default {
    components: {careersCard},
    data() {
        return {
            careers: []
        }
    },
    created() {
        this.getCareers()
    },
    methods: {
        async getCareers() {
            try {
                this.$store.dispatch('loaderChange', true)
                this.careers = await (await axios.get(`${API}/guest/careers`)).data.data
                this.$store.dispatch('loaderChange', false)
            } catch (error) {
                console.log(error)
            }
        }
    }

}
</script>

<style>

</style>