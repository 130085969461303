<template>
		
	<v-row wrap>
		<v-col cols="6" v-if="$vuetify.breakpoint.smAndUp" style="background-color: #F0F2F5;">
			<v-container fill-height style="heigth: 100vh;">
				<v-row wrap justify="center" align="center">
					<v-col cols="6">
						<v-row wrap>
							<v-col cols="12">
								<v-img
									:src="
										require('../../assets/img/vagas.png')
									"
									max-width="150"
									max-height="150"
									alt="Gugla Empresas"
								>
								</v-img>
								<p>
									Vagas de emprego das maiores empresas de Moçambique encontram-se aqui. Novas vagas todos os dias.
								</p>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-col>
		<v-col :cols="isMobile ? '6' : '12'" style="background-color: #F0F2F5;">
			<v-container fill-height style="height: 100vh;">
				<v-row wrap justify="center" align="center">
					<v-col cols="12" md="6">
						<v-container fill-height fluid>
							<v-row wrap justify="center" align="center">
								<v-col>
									<v-form @submit.prevent="forgotPassword">
										<v-card class="elevation-12">
											<v-card-title style="background-color:#009AAB;">
												<v-row justify="center">
													<span class="text1">Recuperar Senha</span>
												</v-row>
											</v-card-title>
											<v-divider style=""></v-divider>
											<v-card-text>
												<v-text-field v-model="formData.email" label="Email" />
											</v-card-text>
											<v-card-actions>
												<v-btn
													type="submit"
													small
													elevation="4"
													dark
													color="#009AAB"
													>Enviar</v-btn
												>
												<v-spacer></v-spacer>
												<v-btn
													small
													elevation="4"
													outlined
													dark
													color="#009AAB"
													:to="{name: 'candidate.login'}"
													>Cancelar</v-btn
												>
											</v-card-actions>
										</v-card>
									</v-form>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>				
			</v-container>
		</v-col>
		<loader/>
	</v-row>
	
</template>

<script>
import Loader from '../loader/Loader.vue'
import Swal from "sweetalert2";
export default {
	// eslint-disable-next-line vue/no-unused-components
	components: {Loader},

	data() {
		return {
			formData: {
				email: "",
			},
			tab: null,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		},
	},
	methods: {
		forgotPassword() {
			this.$store.dispatch('loaderChange', true)
			this.$store
				.dispatch("forgotPassword", this.formData)
				.then(() => {
					this.$store.dispatch('loaderChange', false)
					Swal.fire({
						title: "Sucesso!",
						text: "Receberá um email para poder redefinir a sua senha dentro de instantes!",
						icon: "success",
						confirmButtonText: "OK",
						confirmButtonColor: "#FF1744",
					});

					this.$router.push({ name: "candidate.login" });
				})
				.catch(() => this.$store.dispatch('loaderChange', false ));
		},
	},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
.red-gugla {
	color: #ff1744;
}

.basil {
	background-color: #fffbe6 !important;
}
.basil--text {
	color: #356859 !important;
}

.shadow-left {
	box-shadow: 20px 20px 20px 20px #888888; /* for all browsers */
	-moz-box-shadow: 20px 20px 20px 20px #888888; /* for old firefox */
	-webkit-box-shadow: 20px 20px 20px 20px #888888; /* override for Chrome & Safari */
}
.text1 {
	text-align:center; 
	font-size:23px; 
	text-transform:uppercase; 
	color:rgb(255, 255, 255); 
	letter-spacing:1px;
	font-family:"Roboto", sans-serif;
	padding: 20px;
}
.register {
	color: cornflowerblue;
}
.register:hover {
	cursor: pointer;
}
</style>